import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'column'
  },
  column: {
    display: 'flex'
  },
  avatar: {
    margin: 10,
    width: 100,
    height: 100
  },
  container__location: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1rem'
  },
  container__info_top: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '0.5rem',
    justifyContent: 'space-between'
  },
  container__info_user: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.grayLight,
    borderRadius: '4px'
  },
  full__name: {
    fontSize: '1.2rem',
    fontWeight: 'bold'
  },
  container__user_stats: {
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%'
  },
  container__user_stats_top: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '0.5rem',
    justifyContent: 'space-between',
    marginBottom: '0.5rem'
  },
  container__user_stats_bottom: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: '0.5rem',
    justifyContent: 'space-between'
  },
  user__stats: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: theme.palette.primary.grayLight,
    padding: '0.5rem',
    borderRadius: '4px'
  },
  text__user_stats: {
    fontSize: '0.8rem',
    fontWeight: 'bold'
  }
}));
