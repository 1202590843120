import { makeStyles } from '@material-ui/core';

export default makeStyles({
  header: {
    marginBottom: '1rem'
  },
  content__cards: {
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: 'repeat(4, 1fr)'
  },
  card: {}
});
