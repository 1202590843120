import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  container: {
    padding: '1rem',
    minHeight: '80vh'
  },
  container__input_search: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gridGap: '1rem',
    alignItems: 'center',
    width: '50%'
  },
  input: {
    flex: 1
  },
  iconButton: {
    marginRight: '0.5rem'
  }
}));
