import createReducer from '../createRecucer';
import { CREATE_NUTRITION_TARGET, HANDLE_ERROR, LOADING, GET_NUTRITION_TARGETS } from './types';

export const INITIAL_STATE_TARGET = {
    data: null,
    list: null,
    error: null,
    loading: false,
    success: false
  };

const handleError = (state, action) => {
    return {
        ...state,
        error: action.payload
    };
};

const setLoading = (state, action) => {
    return {
        ...state,
        loading: action.payload
    };
};

const createNutritionTarget = (state, action) => {
    return {
        ...state,
        data: action.payload,
        success: true
    };
};

const getNutritionTargets = (state, action) => {
    return {
        ...state,
        list: action.payload
    };
};

export default createReducer(INITIAL_STATE_TARGET, {
    [LOADING]: setLoading,
    [HANDLE_ERROR]: handleError,
    [CREATE_NUTRITION_TARGET]: createNutritionTarget,
    [GET_NUTRITION_TARGETS]: getNutritionTargets
});

