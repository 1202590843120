import createReducer from '../createRecucer';
import { CREATE_RECIPE, HANDLE_ERROR, LOADING, GET_RECIPES, CLEAR_RECIPE, GET_RECIPE } from './types';

export const INITIAL_STATE_RECIPE = {
  data: null,
  list: null,
  error: null,
  loading: false,
  success: false
};

const handleError = (state, action) => {
  return {
    ...state,
    error: action.payload
  };
};

const setLoading = (state, action) => {
  return {
    ...state,
    loading: action.payload
  };
};

const createRecipe = (state, action) => {
  return {
    ...state,
    data: action.payload,
    success: true
  };
};

const getRecipes = (state, action) => {
  return {
    ...state,
    list: action.payload
  };
};

const getRecipe = (state, action) => {
  return {
    ...state,
    data: action.payload
  };
};

const clearRecipe = (state, _) => {
  return {
    ...state,
    data: null
  };
};

export default createReducer(INITIAL_STATE_RECIPE, {
  [LOADING]: setLoading,
  [HANDLE_ERROR]: handleError,
  [CREATE_RECIPE]: createRecipe,
  [GET_RECIPES]: getRecipes,
  [GET_RECIPE]: getRecipe,
  [CLEAR_RECIPE]: clearRecipe
});
