import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { TextField, Button, IconButton, InputAdornment, Checkbox, FormControlLabel } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';
import { StoreContext } from '../../../../flux';
import { createArticle, updateArticle, getArticle } from '../../../../flux/article/actions';
import Loading from '../../../../components/Loading';
import useStyles from './style';

const INITIAL_STATE_FORM = {
  title: '',
  locTitle: { en: '', es: '' },
  subtitle: '',
  locSubtitle: { en: '', es: '' },
  topic: '',
  locTopic: { en: '', es: '' },
  body: '',
  locBody: { en: '', es: '' },
  readingTime: '',
  locReadingTime: { en: '', es: '' },
  isVisible: true,
  isPremium: false,
  totalLikes: Math.floor(Math.random() * 100) + 10
};

export default withRouter((props) => {
  const { history, match } = props;
  const { state, articleDispatch } = useContext(StoreContext);
  const { article } = state;
  const {
    params: { id }
  } = match;
  const { loading, success, data } = article;
  const styles = useStyles();

  const [form, setValues] = useState(INITIAL_STATE_FORM);
  useEffect(() => {
    setValues(INITIAL_STATE_FORM);
    setFile(null);
  }, [success]);

  useEffect(() => {
    if (id !== undefined) {
      getArticle(id, articleDispatch);
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      const {
        title,
        locTitle,
        subtitle,
        locSubtitle,
        topic,
        locTopic,
        body,
        locBody,
        readingTime,
        locReadingTime,
        isVisible,
        isPremium,
        totalLikes
      } = data;
      setValues({
        title,
        locTitle,
        subtitle,
        locSubtitle,
        topic,
        locTopic,
        body,
        locBody,
        readingTime,
        locReadingTime,
        isVisible,
        isPremium,
        totalLikes
      });
    }
  }, [data]);

  // const onChangeText = (target) => (event) => {
  //   setValues({ ...form, [target]: event.target.value });
  // };

  const onChangeText = (target, loc) => (event) => {
    if (loc) {
      let data = { [loc]: event.target.value };
      setValues({ ...form, [target]: { en: form[target].en, es: form[target].es, ...data } });
    } else {
      setValues({ ...form, [target]: event.target.value });
    }
  };

  const onSaveArticle = async () => {
    console.log('form', form);
    if (id) {
      await updateArticle({ ...form, file, thumbnail, id }, articleDispatch);
    } else {
      await createArticle({ ...form, file, thumbnail }, articleDispatch);
    }
    history.push('/articles');
  };

  const [file, setFile] = useState(null);
  const onUploadImage = () => ({ target }) => {
    console.log('onUploadImage', target);
    setFile(target.files[0]);
  };

  const [thumbnail, setThumbnail] = useState(null);
  const onUploadThumbnail = () => ({ target }) => {
    console.log('target.files', target.files);
    setThumbnail(target.files[0]);
  };

  const handleChange = (target) => (event) => {
    console.log('target', target);
    console.log('event', event);
    setValues({ ...form, [target]: !form[target] });
  };

  // = name => event => {
  //   console.log(name)
  //   // setState({ ...state, [name]: event.target.checked });
  // };

  return (
    <div>
      <Loading open={loading} />
      <form className={styles.container} noValidate autoComplete={'off'}>
        <TextField
          label={'Titulo'}
          className={classNames(styles.text__field)}
          value={form.title}
          onChange={onChangeText('title')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
          required
        />

        <TextField
          label={'Titulo Es'}
          className={classNames(styles.text__field)}
          value={form.locTitle ? form.locTitle.es : ''}
          onChange={onChangeText('locTitle', 'es')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
        />

        <TextField
          label={'Titulo En'}
          className={classNames(styles.text__field)}
          value={form.locTitle ? form.locTitle.en : ''}
          onChange={onChangeText('locTitle', 'en')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
        />
        <Divider variant="middle" />

        <TextField
          label={'Subtitulo'}
          className={classNames(styles.text__field)}
          value={form.subtitle}
          onChange={onChangeText('subtitle')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
        />

        <TextField
          label={'Subtitulo Es'}
          className={classNames(styles.text__field)}
          value={form.locSubtitle ? form.locSubtitle.es : ''}
          onChange={onChangeText('locSubtitle', 'es')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
        />

        <TextField
          label={'Subtitulo En'}
          className={classNames(styles.text__field)}
          value={form.locSubtitle ? form.locSubtitle.en : ''}
          onChange={onChangeText('locSubtitle', 'en')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
        />
        <Divider variant="middle" />

        <TextField
          label={'Topic'}
          className={classNames(styles.text__field)}
          value={form.topic}
          onChange={onChangeText('topic')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
        />

        <TextField
          label={'Topic Es'}
          className={classNames(styles.text__field)}
          value={form.locTopic ? form.locTopic.es : ''}
          onChange={onChangeText('locTopic', 'es')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
        />

        <TextField
          label={'Topic En'}
          className={classNames(styles.text__field)}
          value={form.locTopic ? form.locTopic.en : ''}
          onChange={onChangeText('locTopic', 'en')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
        />
        <Divider variant="middle" />

        <TextField
          label={'Tiempo de Lectura'}
          className={classNames(styles.text__field)}
          value={form.readingTime}
          onChange={onChangeText('readingTime')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
        />

        <TextField
          label={'Tiempo de Lectura Es'}
          className={classNames(styles.text__field)}
          value={form.locReadingTime ? form.locReadingTime.es : ''}
          onChange={onChangeText('locReadingTime', 'es')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
        />

        <TextField
          label={'Tiempo de Lectura En'}
          className={classNames(styles.text__field)}
          value={form.locReadingTime ? form.locReadingTime.en : ''}
          onChange={onChangeText('locReadingTime', 'en')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
        />
        <Divider variant="middle" />

        <FormControlLabel
          value={form.isPremium}
          control={
            <Checkbox
              checked={form.isPremium}
              onChange={handleChange('isPremium')}
              value={form.isPremium}
              inputProps={{
                'aria-label': 'primary checkbox'
              }}
            />
          }
          label="Es Premium"
          labelPlacement="start"
        />

        <FormControlLabel
          value={form.isVisible}
          control={
            <Checkbox
              checked={form.isVisible}
              onChange={handleChange('isVisible')}
              value={form.isPremium}
              inputProps={{
                'aria-label': 'primary checkbox'
              }}
            />
          }
          label="Es Visible"
          labelPlacement="start"
        />
        <Divider variant="middle" />

        <TextField
          label={'Total Likes'}
          className={classNames(styles.text__field)}
          value={form.totalLikes}
          onChange={onChangeText('totalLikes')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
        />
        <Divider variant="middle" />

        <TextField
          label={'Contenido'}
          className={classNames(styles.text__field)}
          value={form.body}
          onChange={onChangeText('body')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
        />

        <TextField
          label={'Contenido Es'}
          className={classNames(styles.text__field)}
          value={form.locBody ? form.locBody.es : ''}
          onChange={onChangeText('locBody', 'es')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
        />

        <TextField
          label={'Contenido En'}
          className={classNames(styles.text__field)}
          value={form.locBody ? form.locBody.en : ''}
          onChange={onChangeText('locBody', 'en')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
        />
        <Divider variant="middle" />

        <div className={styles.upload__container}>
          <input
            accept="image/png"
            className={styles.input}
            id="outlined-button-file"
            type="file"
            onChange={onUploadImage()}
          />
          <label htmlFor="outlined-button-file" className={styles.button__file}>
            <Button variant="text" component="span" size={'large'}>
              <CloudUpload className={styles.icon__upload} />
              {'Subir Imagen'}
            </Button>
          </label>
          <TextField
            label={'Imagen'}
            className={classNames(styles.text__field)}
            value={(file && file.name) || ''}
            variant={'outlined'}
            margin={'dense'}
            fullWidth
            disabled
            component="span"
            required
          />
        </div>

        <div className={styles.upload__container}>
          <input
            accept="image/png"
            className={styles.input}
            id="outlined-button-thumbnail"
            type="file"
            onChange={onUploadThumbnail()}
          />
          <label htmlFor="outlined-button-thumbnail" className={styles.button__file}>
            <Button variant="text" component="span" size={'large'}>
              <CloudUpload className={styles.icon__upload} />
              {'Subir thumbnail'}
            </Button>
          </label>
          <TextField
            label={'Thumbnail'}
            className={classNames(styles.text__field)}
            value={(thumbnail && thumbnail.name) || ''}
            variant={'outlined'}
            margin={'dense'}
            fullWidth
            disabled
            component="span"
            required
          />
        </div>
      </form>
      <Button variant={'contained'} color={'secondary'} onClick={onSaveArticle} className={styles.btn__save}>
        {'Guardar'}
      </Button>
    </div>
  );
});
