import React, { Fragment, forwardRef, useContext, useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { withTheme } from '@material-ui/core/styles';
import { Drawer, List, Divider, ListItem, ListItemIcon, ListItemText, IconButton } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { StoreContext } from '../../../flux';
import { ROUTES } from '../../../config/RouterCms/routes';
import logo from '../../../assets/images/logo.png';
import useStyles from './style';

const DrawerComponent = (props) => {
  const { theme, onCloseMenu, isOpen } = props;
  const { state } = useContext(StoreContext);
  const { layout } = state;
  const styles = useStyles();

  const [selectedPath, setSelectedPath] = React.useState('');
  useEffect(() => {
    setSelectedPath(layout.path_name);
  }, [layout.path_name]);

  return (
    <Fragment>
      <Drawer
        variant={'permanent'}
        className={classNames(styles.drawer, {
          [styles.drawerOpen]: isOpen,
          [styles.drawerClose]: !isOpen
        })}
        classes={{
          paper: classNames(styles.drawerBg, {
            [styles.drawerOpen]: isOpen,
            [styles.drawerClose]: !isOpen
          })
        }}
        open={isOpen}
      >
        <div className={classNames(styles.toolbar)}>
          <div className={styles.logo}>
            <img src={logo} alt={'Logo Orux'} />
          </div>
          <IconButton onClick={() => onCloseMenu(false)}>
            {theme.direction === 'rtl' ? (
              <ChevronRight classes={{ root: styles.listItem }} />
            ) : (
              <ChevronLeft classes={{ root: styles.listItem }} />
            )}
          </IconButton>
        </div>
        <List>
          {ROUTES.map((item, index) => {
            const Icon = item.icon;
            return (
              <Fragment key={index}>
                {item.show_menu && (
                  <ListItem
                    button
                    selected={selectedPath === item.path}
                    component={forwardRef((itemProps, ref) => (
                      <Link to={item.path} {...itemProps} innerRef={ref} />
                    ))}
                    classes={{ root: styles.listItem }}
                  >
                    {item.icon && (
                      <ListItemIcon classes={{ root: styles.listItem }}>
                        <Icon />
                      </ListItemIcon>
                    )}
                    <ListItemText primary={item.name} />
                  </ListItem>
                )}
              </Fragment>
            );
          })}
        </List>
      </Drawer>
    </Fragment>
  );
};

export default withTheme(DrawerComponent);
