import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import logo from '../../../assets/images/logo.png';
import { login } from '../../../flux/user/actions';
import useStyles from './style';
import { StoreContext } from '../../../flux';

const INITIAL_STATE_FORM = {
  email: '',
  password: ''
};

export default withRouter((props) => {
  const { history } = props;
  const { state, userDispatch } = useContext(StoreContext);
  const { user } = state;
  const styles = useStyles();

  const [form, setValues] = useState(INITIAL_STATE_FORM);
  useEffect(() => {
    if (user.token) {
      setValues(INITIAL_STATE_FORM);
      history.push('/');
    }
  }, [user.token]);

  const onChangeText = (target) => (event) => {
    setValues({ ...form, [target]: event.target.value });
  };

  const onLogin = () => {
    login(form, userDispatch);
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <CardMedia component={'div'} className={styles.logo}>
          <img src={logo} />
          <Typography component={'h1'} variant={'h5'} className={styles.login__text}>
            {'Iniciar sesión'}
          </Typography>
        </CardMedia>
        <CardContent>
          <div className={styles.form} noValidate>
            <TextField
              variant={'outlined'}
              margin={'normal'}
              required
              fullWidth
              value={form.email}
              label={'Email'}
              name={'email'}
              autoComplete={'email'}
              autoFocus
              onChange={onChangeText('email')}
            />
            <TextField
              variant={'outlined'}
              margin={'normal'}
              required
              fullWidth
              name={'password'}
              label={'Contraseña'}
              type={'password'}
              value={form.password}
              autoComplete={'current-password'}
              onChange={onChangeText('password')}
            />
            <FormControlLabel control={<Checkbox value={'remember'} color={'primary'} />} label={'Recuérdame'} />
            <Button
              type={'submit'}
              fullWidth
              variant={'contained'}
              color={'secondary'}
              className={styles.submit}
              onClick={onLogin}
            >
              {'Entrar'}
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
});
