import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { auth } from '../Firebase';
import { StoreContext } from '../../flux';

export default class extends Component {
  render() {
    const { component: Component } = this.props;

    const token = sessionStorage.token;

    return (
      <Route
        render={(props) => {
          if (token) {
            return <Component {...props} />;
          } else {
            return <Redirect to="/login" />;
          }
        }}
      />
    );
  }
}
