import { makeStyles } from '@material-ui/core';

const drawerWidth = 240;

export default makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(1) * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(1) * 9 + 1
    }
  },
  drawerBg: {
    backgroundColor: theme.palette.primary.light
  },
  listItem: {
    color: theme.palette.primary.contrastText
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    backgroundColor: theme.palette.primary.main,
    ...theme.mixins.toolbar
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  }
}));
