import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '70%',
    marginBottom: theme.spacing(1)
  },
  text__field: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  menu: {
    width: 200
  },
  toggleContainer: {
    margin: theme.spacing(2, 0)
  },
  button__file: {
    marginLeft: theme.spacing(1)
  },
  input: {
    display: 'none'
  },

  upload__container: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    alignItems: 'center',
    flexDirection: 'row',
    width: '97%'
  },


  nutritional__container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '97%',
  },

  icon__upload: {
    marginRight: theme.spacing(1)
  },
  title__upload: {
    fontSize: '1rem',
    marginLeft: theme.spacing(1)
  },
  btn__save: {
    marginLeft: theme.spacing(1)
  }
}));
