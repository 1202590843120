import { db, storage } from '../../config/Firebase';
import utilities from '../../util';
import { CREATE_INGREDIENT, HANDLE_ERROR, LOADING, GET_INGREDIENTS, GET_INGREDIENT, CLEAR_INGREDIENT } from './types';

export const handleError = (error, dispatch) => {
  console.error('error', error);
  return dispatch({ type: HANDLE_ERROR, payload: error });
};
export const setLoading = (loading, dispatch) => {
  return dispatch({ type: LOADING, payload: loading });
};

export const createIngredient = async (data, dispatch) => {
  try {
    setLoading(true, dispatch);
    const image = await uploadImage(data.file);
    const thumbnail = await uploadThumbnail(data.thumbnail);
    if (!image.error && !thumbnail.error) {
      delete data.file;
      const ingredientsRef = db.collection('ingredients');
      const date = new Date();
      const result = await ingredientsRef.add({ ...data, ...image, ...thumbnail, createDate: date });
      const ingredient = await result.get();

      console.log('ingredient', ingredient);

      setLoading(false, dispatch);
      return dispatch({
        type: CREATE_INGREDIENT,
        payload: { ...ingredient.data(), id: ingredient.id }
      });
    } else {
      throw new Error(image.error ? image.message : thumbnail.message);
    }
  } catch (error) {
    return handleError(error, dispatch);
  }
};

export const updateIngredient = async (data, dispatch) => {
  try {
    setLoading(true, dispatch);
    let image, thumbnail;
    if (data.file) {
      image = await uploadImage(data.file);
    }
    if (data.thumbnail) {
      thumbnail = await uploadThumbnail(data.thumbnail);
    }

    if (data.file && image.error) {
      throw new Error(image);
    }
    if (data.thumbnail && thumbnail.error) {
      throw new Error(thumbnail);
    }

    delete data.file;
    delete data.thumbnail;
    delete data.image;

    const refIngredient = await db.collection('ingredients').doc(data.id);
    delete data.id;
    let media = Object.assign({}, { ...image, ...thumbnail });

    await refIngredient.set(
      {
        ...data,
        ...media
      },
      { merge: true }
    );
    setLoading(false, dispatch);
    dispatch({ type: CLEAR_INGREDIENT });
  } catch (error) {
    return handleError(error, dispatch);
  }
};

const uploadImage = async (file) => {
  try {
    const imageRef = await storage.ref(`ingredientsImages/${utilities.create_UUID()}.png`);

    const image = await imageRef.put(file);
    const imageUrl = await imageRef.getDownloadURL();
    return {
      imageUrl,
      image: image.metadata.name
    };
  } catch (error) {
    return { error: true, message: error.message };
  }
};

const uploadThumbnail = async (file) => {
  console.log('file', file);
  try {
    const thumbnailRef = await storage.ref(`ingredientsImagesThumbnails/${utilities.create_UUID()}.png`);

    const thumbnail = await thumbnailRef.put(file);
    const thumbnailUrl = await thumbnailRef.getDownloadURL();
    return {
      thumbnailUrl,
      thumbnail: thumbnail.metadata.name
    };
  } catch (error) {
    return { error: true, message: error.message };
  }
};

export const getIngredients = async (dispatch) => {
  try {
    setLoading(true, dispatch);
    const ingredients = await db.collection('ingredients').get();
    const response = await ingredients.docs.map((item) => {
      return {
        id: item.id,
        ...item.data()
      };
    });
    setLoading(false, dispatch);
    return dispatch({ type: GET_INGREDIENTS, payload: response });
  } catch (error) {
    return handleError(error, dispatch);
  }
};

export const getIngredient = async (id, dispatch) => {
  try {
    setLoading(true, dispatch);
    const ingredient = await db
      .collection('ingredients')
      .doc(id)
      .get();
    setLoading(false, dispatch);
    return dispatch({ type: GET_INGREDIENT, payload: { id: ingredient.id, ...ingredient.data() } });
  } catch (error) {
    return handleError(error, dispatch);
  }
};

export const deleteIgredient = async (id, dispatch) => {
  try {
    setLoading(true, dispatch);
    await db
      .collection('ingredients')
      .doc(id)
      .delete();
    getIngredients(dispatch);
  } catch (error) {
    return handleError(error, dispatch);
  }
};
