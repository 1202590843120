import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 360,
    margin: '0 auto',
    height: '100vh'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    padding: theme.spacing(2),
    borderRadius: 4,
    width: '100%',
    margin: 'auto',
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center'
  },
  login__text: {
    color: theme.palette.primary.contrastText,
    marginTop: theme.spacing(1)
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));
