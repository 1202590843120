import React, { forwardRef, Fragment, useContext, useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core';
import useStyles from './style';
import { withRouter } from 'react-router-dom';
import { withTheme } from '@material-ui/styles';
import { StoreContext } from '../../../../flux';
import { getArticles, deleteArticle } from '../../../../flux/article/actions';
import Loading from '../../../../components/Loading';

export default withRouter((props) => {
  const { state, articleDispatch } = useContext(StoreContext);
  const { article } = state;
  const { history } = props;
  const styles = useStyles();

  useEffect(() => {
    getArticles(articleDispatch);
  }, [!articles]);

  const [articles, setArticles] = useState(null);
  useEffect(() => {
    setArticles(article.list);
  }, [article.list]);



  const onEdit = (id) => {
    history.push(`article/${id}`);
  };

  // const [_delete, setDelete] = useState(false)
  const confirmDelete = (id) => {
    const confirmation = confirm('Realmente desea eliminar el articulo');
    if (confirmation) {
      onDelete(id);
    }
  };

  const onDelete = (id) => {
    deleteArticle(id, articleDispatch);
  };



  return useMemo(
    () => (
      <Fragment>
        <Loading open={article.loading} />
        <header className={styles.header}>
          <Button
            variant={'contained'}
            color={'secondary'}
            component={forwardRef((itemProps, ref) => (
              <Link to={'/article'} {...itemProps} innerRef={ref} />
            ))}
          >
            {'Nuevo Articulo'}
          </Button>
        </header>
        <section className={styles.content__cards}>
          {articles &&
            articles.map((item) => (
              <Card className={styles.card} key={item.id}>
                <CardActionArea>
                  <CardMedia
                    className={'styles.media'}
                    component="img"
                    image={item.imageUrl}
                    title={'Contemplative Reptile'}
                  />
                  <CardContent>
                    <Typography variant={'h6'} component={'h2'}>
                      {item.title}
                    </Typography>
                    <Typography variant={'subtitle1'} component={'h4'}>
                      {item.subtitle}
                    </Typography>
                    <Typography variant={'subtitle2'} component={'h6'}>
                      {item.topic}
                    </Typography>
                    <Typography variant={'body2'} color={'textSecondary'} component={'p'}>
                      {item.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size={'small'} variant={'contained'} color={'default'} onClick={() => onEdit(item.id)}>
                    {'Editar'}
                  </Button>
                  <Button size={'small'} variant={'contained'} color={'default'} onClick={() => confirmDelete(item.id)}>
                    {'Eliminar'}
                  </Button>
                </CardActions>
              </Card>
            ))}
        </section>
      </Fragment>
    ),
    [articles]
  );
});
