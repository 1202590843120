import { HANDLE_ERROR, LOADING, AUTHENTICATED, SEARCH_RESULT } from './types';
import { auth, db } from '../../config/Firebase';

export const handleError = (error, dispatch) => {
  console.error('error', error);
  return dispatch({ type: HANDLE_ERROR, payload: error });
};

export const setLoading = (loading, dispatch) => {
  return dispatch({ type: LOADING, payload: loading });
};

export const login = async (data, dispatch) => {
  const response = await auth.signInWithEmailAndPassword(data.email, data.password);

  if (response.user) {
    const refUser = await db
      .collection('admins')
      .where('id', '==', response.user.uid)
      .get();

    const is_admin = refUser.docs.some((item) => {
      const config = item.data();
      return config.id === response.user.uid;
    });

    if (is_admin) {
      const result = await response.user.getIdTokenResult();
      sessionStorage.setItem('token', result.token);
      sessionStorage.setItem('uid', response.user.uid);
      return dispatch({ type: AUTHENTICATED, token: result.token, uid: response.user.uid });
    } else {
      alert('No esta autorizado para ingresar');
    }
  }
};

export const searchUser = async (email, dispatch) => {
  try {
    setLoading(true, dispatch);
    const response = await db
      .collection('users')
      .where('profile.email', '==', email)
      .get();
    const user = response.docs.map((item) => {
      return { id: item.id, ...item.data() };
    });
    setLoading(false, dispatch);
    return dispatch({ type: SEARCH_RESULT, payload: user[0] });
  } catch (error) {
    return handleError(error, dispatch);
  }
};

export const updateUser = async (data, dispatch) => {
  try {
    setLoading(true, dispatch);
    const usersRef = await db.collection('users').doc(data.id);
    await usersRef.set(
      {
        ...data.payload
      },
      { merge: true }
    );
    const user = await usersRef.get();
    setLoading(false, dispatch);
    return dispatch({ type: SEARCH_RESULT, payload: { id: user.id, ...user.data() } });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const savePremiumDays = async (data, email, dispatch) => {
  try {
    setLoading(true, dispatch);
    const response = await fetch('https://us-central1-oruxapp-d6f9c.cloudfunctions.net/setSubscription', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    await searchUser(email, dispatch);
    alert('Se agregaron los días correctamente');
  } catch (error) {
    setLoading(false, dispatch);
    alert('Error procesando los datos. Intentalo de nuevo.');
    handleError(error, dispatch);
  }
};
