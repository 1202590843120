import React, { useContext, useState } from 'react';
import { format, differenceInCalendarYears, differenceInDays, getMonth, getDate, addDays } from 'date-fns/';
import {
  TextField,
  Button,
  Divider,
  ExpansionPanel,
  ExpansionPanelSummary,
  Checkbox,
  FormControlLabel,
  MenuItem,
  InputAdornment
} from '@material-ui/core';
import { StoreContext } from '../../../../../flux';
import { updateUser, savePremiumDays } from '../../../../../flux/user/actions';
import constants from '../../../../../util/constants';
import ProfileHeader from '../ProfileHeader';
import useStyles from './style';

export default (props) => {
  const { state, userDispatch } = useContext(StoreContext);
  const { profile, profilePics, notes = [], id, userStats, purchaseUpdateSubscription, email } = props;
  const subscription = purchaseUpdateSubscription ? purchaseUpdateSubscription.code : null;
  const {
    user: { loading, uid }
  } = state;
  const styles = useStyles();

  const [form, setValues] = useState(profile);
  const onChangeText = (target) => (event) => {
    setValues({ ...form, [target]: event.target.value });
  };

  const [note, setNote] = useState('');
  const onChangeTextNote = () => (event) => {
    setNote(event.target.value);
  };

  const [daysPremium, setDaysPremium] = useState(1);
  const onChangeTextAddDays = () => (event) => {
    setDaysPremium(event.target.value);
  };

  const onSaveNote = () => {
    const new_notes = [...notes, { description: note.toUpperCase(), date: format(new Date(), 'DD/MM/YYYY') }];
    updateUser({ id, payload: { notes: new_notes } }, userDispatch);
    setNote('');
  };

  const onUpdateUser = () => {
    updateUser({ id, payload: { profile: form } }, userDispatch);
  };

  const getAge = () => {
    const today = new Date();
    const birth_date = form.birthday;
    let age = differenceInCalendarYears(today, birth_date);
    const diff_months = getMonth(today) - getMonth(birth_date);
    if (diff_months < 0 || (diff_months === 0 && getDate(today) < getDate(birth_date))) {
      age--;
    }
    return isNaN(age) ? '' : age;
  };

  const onAddPremiumDays = async () => {
    const data = {
      uid: id,
      adminId: uid,
      days: parseInt(daysPremium),
      subscription: { endDate: endDate }
    };

    await savePremiumDays(data, email, userDispatch);
    setDaysPremium(0);
  };

  let startDate, addDaysPremium, endDate, missingDays;
  if (subscription) {
    startDate = format(subscription.date, 'YYYY-MM-DD');
    addDaysPremium = addDays(subscription.date, subscription.premiumDays);
    endDate = format(addDaysPremium, 'YYYY-MM-DD');
    missingDays = differenceInDays(addDaysPremium, subscription.date);
  }

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <div className={[styles.row, styles.container__info].join(' ')}>
          <ProfileHeader profile={profile} profilePics={profilePics} userStats={userStats} />
          <div className={styles.container__profile}>
            <div>
              <div className={styles.column}>
                <TextField
                  variant={'outlined'}
                  margin={'dense'}
                  className={styles.input}
                  label={'Fecha de nacimiento'}
                  type={'date'}
                  value={form.birthday}
                  onChange={onChangeText('birthday')}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <TextField
                  variant={'outlined'}
                  margin={'dense'}
                  className={styles.input}
                  placeholder={'Edad'}
                  type={'text'}
                  label={'Edad'}
                  InputProps={{
                    endAdornment: <InputAdornment position={'end'}>{'Años'}</InputAdornment>
                  }}
                  value={getAge()}
                  onChange={onChangeText('age')}
                  disabled={true}
                />
              </div>
              <div className={styles.column}>
                <TextField
                  variant={'outlined'}
                  margin={'dense'}
                  className={styles.input}
                  placeholder={'Estatura'}
                  type={'text'}
                  label={'Estatura'}
                  InputProps={{
                    endAdornment: <InputAdornment position={'end'}>{'CM'}</InputAdornment>
                  }}
                  value={form.height}
                  onChange={onChangeText('height')}
                />
                <TextField
                  variant={'outlined'}
                  margin={'dense'}
                  className={styles.input}
                  placeholder={'Peso'}
                  type={'text'}
                  label={'Peso'}
                  InputProps={{
                    endAdornment: <InputAdornment position={'end'}>{'KG'}</InputAdornment>
                  }}
                  value={form.weight || ''}
                  onChange={onChangeText('weight')}
                />
              </div>
              <div className={styles.column}>
                <TextField
                  variant={'outlined'}
                  margin={'dense'}
                  className={styles.input}
                  select={true}
                  label={'Objetivo'}
                  value={form.objetive}
                  onChange={onChangeText('objetive')}
                >
                  {constants.objetives.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  variant={'outlined'}
                  margin={'dense'}
                  className={styles.input}
                  select={true}
                  label={'Somatotipo'}
                  value={form.somatotipo}
                  onChange={onChangeText('somatotipo')}
                >
                  {constants.somatotipes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className={styles.column}>
                <TextField
                  variant={'outlined'}
                  margin={'dense'}
                  className={styles.input}
                  select={true}
                  label={'Genero'}
                  value={form.gender}
                  onChange={onChangeText('gender')}
                >
                  {constants.gender.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  variant={'outlined'}
                  margin={'dense'}
                  className={styles.input}
                  select={true}
                  label={'Nivel'}
                  value={form.level}
                  onChange={onChangeText('level')}
                >
                  {constants.level.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>

              <Button
                variant={'contained'}
                color={'secondary'}
                onClick={onUpdateUser}
                size={'medium'}
                className={styles.button}
                disabled={loading}
              >
                {'Actualizar'}
              </Button>
            </div>
            <div>
              <TextField
                variant={'outlined'}
                margin={'dense'}
                className={styles.input}
                placeholder={'Escribe una nota'}
                label={'Nota'}
                type={'text'}
                multiline={true}
                rows={4}
                value={note}
                onChange={onChangeTextNote()}
              />
              <div className={styles.column}>
                <Button
                  variant={'contained'}
                  color={'secondary'}
                  onClick={onSaveNote}
                  size={'medium'}
                  className={styles.button}
                  disabled={loading}
                >
                  {'Guardar nota'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className={styles.container__premium}>
        <div>
          <h3>{'Suscripción premium'}</h3>
          <div className={styles.column}>
            <FormControlLabel
              control={<Checkbox checked={form.isPremium} value="checkedB" color="primary" />}
              label="Premium Store"
            />
            <FormControlLabel
              control={<Checkbox checked={form.isPremiumManual} value="checkedB" color="primary" />}
              label="Premium Code"
            />
          </div>
          <div className={styles.summary__subscription}>
            <div className={styles.row}>
              <span>
                {`Inicia: `}
                <strong>{startDate}</strong>
              </span>
              <span>
                {'Termina: '}
                <strong>{endDate}</strong>
              </span>
              <span>
                {'Días restantes: '} <strong>{missingDays}</strong>
              </span>
            </div>
            <div className={styles.container__add_days}>
              <TextField
                variant={'outlined'}
                margin={'dense'}
                className={styles.input}
                label={'Días'}
                type={'number'}
                value={daysPremium}
                onChange={onChangeTextAddDays()}
              />
              <div>
                <Button
                  variant={'contained'}
                  color={'secondary'}
                  onClick={onAddPremiumDays}
                  size={'medium'}
                  className={styles.button_add_days}
                  disabled={loading}
                >
                  {'Agregar días'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Divider />

      <ExpansionPanel expanded square>
        <ExpansionPanelSummary className={styles.panel__header}>
          <span>{'Notas'}</span>
        </ExpansionPanelSummary>
        <div className={styles.container__notes}>
          {notes.map((item, index) => (
            <li key={index} className={styles.text_note}>
              <em className={styles.date__note}>{item.date}</em> - {item.description}
            </li>
          ))}
        </div>
      </ExpansionPanel>
    </div>
  );
};
