import {
  Home as HomeIcon,
  AccountCircle,
  Assignment,
  ViewCompactOutlined,
  Restaurant,
  Videocam,
  Kitchen,
  Fastfood
} from '@material-ui/icons';
import Home from '../../pages/cms/Home';
import { SearchUser } from '../../pages/cms/User';
import { CreateExercise, ListExercises } from '../../pages/cms/Exercises';
import { CreateArticle, ListArticles } from '../../pages/cms/Articles';
import { CreateIngredient, ListIngredients } from '../../pages/cms/Ingredients';
import { CreateRecipe, ListRecipes } from '../../pages/cms/Recipes';
import { CreateBlock } from '../../pages/cms/Blocks';
import { CreateNutritionTarget, ListNutritionTargets } from '../../pages/cms/NutritionTargets';

export const ROUTES = [
  {
    path: '/',
    exact: true,
    name: 'Home',
    title: 'Home',
    component: Home,
    show_menu: true,
    icon: HomeIcon
  },
  {
    path: '/users',
    exact: true,
    name: 'Buscar usuario',
    title: 'Buscar usuario',
    component: SearchUser,
    show_menu: true,
    icon: AccountCircle
  },
  {
    path: '/exercises',
    exact: true,
    name: 'Ejercicios',
    title: 'Ejercicios',
    component: ListExercises,
    show_menu: true,
    icon: Videocam
  },
  {
    path: '/exercise/:id?',
    exact: true,
    name: 'Crear Ejercicio',
    title: 'Crear Ejercicio',
    component: CreateExercise,
    show_menu: false,
    icon: null
  },
  {
    path: '/articles',
    exact: true,
    name: 'Articles',
    title: 'Articles',
    component: ListArticles,
    show_menu: true,
    icon: Assignment
  },
  {
    path: '/article/:id?',
    exact: true,
    name: 'Crear Articulo',
    title: 'Crear Articulo',
    component: CreateArticle,
    show_menu: false,
    icon: null
  },
  {
    path: '/recipes',
    exact: true,
    name: 'Recetas',
    title: 'Recetas',
    component: ListRecipes,
    show_menu: true,
    icon: Fastfood
  },
  {
    path: '/recipe/:id?',
    exact: true,
    name: 'Crear Receta',
    title: 'Crear Receta',
    component: CreateRecipe,
    show_menu: false,
    icon: null
  },

  {
    path: '/ingredients',
    exact: true,
    name: 'Ingredients',
    title: 'Ingredients',
    component: ListIngredients,
    show_menu: true,
    icon: Kitchen
  },
  {
    path: '/ingredient/:id?',
    exact: true,
    name: 'Crear Ingrediente',
    title: 'Crear Ingrediente',
    component: CreateIngredient,
    show_menu: false,
    icon: null
  },
  {
    path: '/block',
    exact: true,
    name: 'Crear Bloque',
    title: 'Crear Bloque',
    component: CreateBlock,
    show_menu: true,
    icon: ViewCompactOutlined
  },
  {
    path: '/nutritionTargets',
    exact: true,
    name: 'Nutrition Targets',
    title: 'Nutrition Targets',
    component: ListNutritionTargets,
    show_menu: true,
    icon: Restaurant
  },
  {
    path: '/nutritionTarget/:id',
    exact: false,
    name: 'Nutrition Target',
    title: 'Nutrition Target',
    component: CreateNutritionTarget,
    show_menu: false,
    icon: null
  }
];
