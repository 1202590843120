import React, { useContext, useState } from 'react';
import { TextField, Button, Paper, Divider, Typography, Card, CardContent } from '@material-ui/core';
import { StoreContext } from '../../../../flux';
import { searchUser } from '../../../../flux/user/actions';
import Loading from '../../../../components/Loading';
import useStyles from './style';
import constants from '../../../../util/constants';

const INITIAL_STATE_FORM = {
  name: '',
  exercises: []
};
const EXERCISE_CONFIGURATION = {
  level: ''
};

export default () => {
  const { state, userDispatch } = useContext(StoreContext);
  const { block } = state;
  const styles = useStyles();

  const [form, setValues] = useState(INITIAL_STATE_FORM);
  const onChangeText = (target) => (event) => {
    setValues({ ...form, [target]: event.target.value });
  };
  const [exercise, setValuesExercise] = useState(EXERCISE_CONFIGURATION);
  const onChangeTextExcercise = (target) => (event) => {
    setValuesExercise({ ...exercise, [target]: event.target.value });
  };

  // const [show_configuration, setShowConfiguration] = useState(false);
  // const onChangeConfigutation = (target) => (event) => {
  //   setShowConfiguration();
  // };

  const onSearchUser = () => {
    if (email !== '') {
      searchUser(email, userDispatch);
    }
  };

  return (
    <Paper className={styles.container}>
      <Loading open={false} />
      <div className={styles.form}>
        <div className={styles.row}>
          <div className={styles.column}>
            <TextField
              variant={'outlined'}
              margin={'dense'}
              className={styles.input}
              placeholder={'Nombre'}
              type={'text'}
              label={'Nombre'}
              value={form.name}
              onChange={onChangeText('name')}
            />
          </div>
        </div>
        <Divider />
        <h2>{'Configuración'}</h2>
        <div className={styles.container_level}>
          <Card className={styles.card}>
            <CardContent>
              <span className={styles.title_level}>{'Principiante'}</span>

              <TextField
                variant={'outlined'}
                margin={'dense'}
                className={styles.input_level}
                placeholder={'Repeticiones'}
                type={'text'}
                label={'Repeticiones'}
                value={exercise.repetitions}
                onChange={onChangeText('repetitions')}
              />
              <TextField
                variant={'outlined'}
                margin={'dense'}
                className={styles.input_level}
                placeholder={'Tiempo'}
                type={'text'}
                label={'Tiempo'}
                value={exercise.time}
                onChange={onChangeText('time')}
              />
            </CardContent>
          </Card>
          <Card className={styles.card}>
            <CardContent>
              <span className={styles.title_level}>{'Avanzado'}</span>
              <TextField
                variant={'outlined'}
                margin={'dense'}
                className={styles.input_level}
                placeholder={'Repeticiones'}
                type={'text'}
                label={'Repeticiones'}
                value={exercise.repetitions}
                onChange={onChangeText('repetitions')}
              />
              <TextField
                variant={'outlined'}
                margin={'dense'}
                className={styles.input_level}
                placeholder={'Tiempo'}
                type={'text'}
                label={'Tiempo'}
                value={exercise.time}
                onChange={onChangeText('time')}
              />
            </CardContent>
          </Card>
          <Card className={styles.card}>
            <CardContent>
              <span className={styles.title_level}>{'Atleta'}</span>
              <TextField
                variant={'outlined'}
                margin={'dense'}
                className={styles.input_level}
                placeholder={'Repeticiones'}
                type={'text'}
                label={'Repeticiones'}
                value={exercise.repetitions}
                onChange={onChangeText('repetitions')}
              />
              <TextField
                variant={'outlined'}
                margin={'dense'}
                className={styles.input_level}
                placeholder={'Tiempo'}
                type={'text'}
                label={'Tiempo'}
                value={exercise.time}
                onChange={onChangeText('time')}
              />
            </CardContent>
          </Card>
        </div>

        <div className={styles.row}>
          <div className={styles.column}>
            <TextField
              variant={'outlined'}
              margin={'dense'}
              className={styles.input}
              placeholder={'Variación'}
              type={'text'}
              label={'Variación'}
              value={exercise.vatiation}
              onChange={onChangeText('vatiation')}
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.add_exercise}>
            <Button
              variant={'outlined'}
              color={'primary'}
              // onClick={onSaveNote}
              size={'medium'}
              className={styles.button_exercise}
              // disabled={loading}
            >
              {'Agregar ejercicio'}
            </Button>
            <TextField
              variant={'outlined'}
              margin={'dense'}
              className={styles.input_exercise}
              placeholder={'Ejercicio'}
              type={'text'}
              label={'Ejercicio'}
              value={exercise.id}
              onChange={onChangeText('id')}
            />
          </div>
        </div>
        <div className={styles.row}>
          <Button
            variant={'contained'}
            color={'secondary'}
            // onClick={onSaveNote}
            size={'medium'}
            className={styles.button_save}
            // disabled={loading}
          >
            {'Guardar'}
          </Button>
        </div>
      </div>
    </Paper>
  );
};
