import createReducer from '../createRecucer';
import { CREATE_INGREDIENT, HANDLE_ERROR, LOADING, GET_INGREDIENTS, CLEAR_INGREDIENT, GET_INGREDIENT } from './types';

export const INITIAL_STATE_INGREDIENT = {
  data: null,
  list: null,
  error: null,
  loading: false,
  success: false
};

const handleError = (state, action) => {
  return {
    ...state,
    error: action.payload
  };
};

const setLoading = (state, action) => {
  return {
    ...state,
    loading: action.payload
  };
};

const createIngredient = (state, action) => {
  return {
    ...state,
    data: action.payload,
    success: true
  };
};

const getIngredients = (state, action) => {
  return {
    ...state,
    list: action.payload
  };
};

const getIngredient = (state, action) => {
  return {
    ...state,
    data: action.payload
  };
};

const clearIngredient = (state, _) => {
  return {
    ...state,
    data: null
  };
};

export default createReducer(INITIAL_STATE_INGREDIENT, {
  [LOADING]: setLoading,
  [HANDLE_ERROR]: handleError,
  [CREATE_INGREDIENT]: createIngredient,
  [GET_INGREDIENTS]: getIngredients,
  [GET_INGREDIENT]: getIngredient,
  [CLEAR_INGREDIENT]: clearIngredient
});
