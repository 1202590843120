import React, { useContext, useState } from 'react';
import { TextField, Button, Paper, Divider } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { StoreContext } from '../../../../flux';
import { searchUser } from '../../../../flux/user/actions';
import Profile from './Profile';
import Loading from '../../../../components/Loading';
import useStyles from './style';

export default () => {
  const { state, userDispatch } = useContext(StoreContext);
  const { user } = state;
  const { search_result, loading } = user;
  const styles = useStyles();

  const [email, setEmail] = useState('');
  const onChangeText = () => (event) => {
    setEmail(event.target.value);
  };

  const onSearchUser = () => {
    if (email !== '') {
      searchUser(email, userDispatch);
    }
  };

  return (
    <Paper className={styles.container}>
      <Loading open={loading} />
      <div className={styles.container__input_search}>
        <TextField
          variant={'outlined'}
          margin={'dense'}
          className={styles.input}
          placeholder={'Email'}
          label={'Email'}
          type={'email'}
          autoComplete={'email'}
          value={email}
          onChange={onChangeText()}
        />
        <Button variant={'contained'} color={'secondary'} onClick={onSearchUser} size={'medium'}>
          <SearchIcon className={styles.iconButton} />
          {'Buscar'}
        </Button>
      </div>
      <Divider />
      {search_result.profile ? <Profile {...search_result} email={email} /> : <span>{search_result.not_found}</span>}
    </Paper>
  );
};
