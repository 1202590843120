import { db, storage } from '../../config/Firebase';
import utilities from '../../util';
import {  CREATE_NUTRITION_TARGET, HANDLE_ERROR, LOADING, GET_NUTRITION_TARGETS } from './types';

export const handleError = (error, dispatch) => {
    console.error('error', error);
    return dispatch({ type: HANDLE_ERROR, payload: error });
  };
  export const setLoading = (loading, dispatch) => {
    return dispatch({ type: LOADING, payload: loading });
  };


export const createNutritionTarget = async (data, dispatch) => {
    try {
        setLoading(true, dispatch);
        const image = await uploadImage(data.file);
        if(!image.error) {
            delete data.file;
            const targetsRef = db.collection('nutritionTargetsDev');
            const result = await targetsRef.add({...data, ...image});
            const target = await result.get();

            console.log('target', target);

            setLoading(false, dispatch);
            return dispatch({
                type: CREATE_NUTRITION_TARGET,
                payload: { ...target.data(), id: target.id }
            });
        } else {
            throw new Error(image.message);
        }
        
    } catch (error) {
        return handleError(error, dispatch);
    }
}

const uploadImage = async (file) => {
    try {
        const imageRef = await storage.ref(`nutritionTargetsImages/${utilities.create_UUID()}.png`);
        
        const image = await imageRef.put(file);
        const imageUrl = await imageRef.getDownloadURL();

        return {
            imageUrl,
            image: image.metadata.name
        };
    } catch (error) {
        return { error: true, message: error.message };
    }
}

export const getNutritionTargets = async (dispatch) => {
    try {
        setLoading(true, dispatch);
        const targets = await db.collection('targets').get();
        const response = await targets.docs.map((item) => {
            return {
                id: item.id,
                ...item.data()
            };
        });
        setLoading(false, dispatch);
        return dispatch({ type: GET_NUTRITION_TARGETS, payload: response });
    } catch (error) {
        return handleError(error, dispatch);
    }

}
 