import createReducer from '../createRecucer';
import { CREATE_ARTICLE, HANDLE_ERROR, LOADING, GET_ARTICLES, CLEAR_ARTICLE, GET_ARTICLE } from './types';

export const INITIAL_STATE_ARTICLE = {
  data: null,
  list: null,
  error: null,
  loading: false,
  success: false
};

const handleError = (state, action) => {
  return {
    ...state,
    error: action.payload
  };
};

const setLoading = (state, action) => {
  return {
    ...state,
    loading: action.payload
  };
};

const createArticle = (state, action) => {
  return {
    ...state,
    data: action.payload,
    success: true
  };
};

const getArticles = (state, action) => {
  return {
    ...state,
    list: action.payload
  };
};

const getArticle = (state, action) => {
  return {
    ...state,
    data: action.payload
  };
};

const clearArticle = (state, _) => {
  return {
    ...state,
    data: null
  };
};

export default createReducer(INITIAL_STATE_ARTICLE, {
  [LOADING]: setLoading,
  [HANDLE_ERROR]: handleError,
  [CREATE_ARTICLE]: createArticle,
  [GET_ARTICLES]: getArticles,
  [GET_ARTICLE]: getArticle,
  [CLEAR_ARTICLE]: clearArticle
});
