import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import GlobalCss from './assets/styles/global';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#484848',
      main: '#212121',
      dark: '#000000',
      contrastText: '#ffffff',
      grayLight: '#eee'
    },
    secondary: {
      light: '#ffffbf',
      main: '#ffff8d',
      dark: '#cacc5d',
      contrastText: '#000000'
    }
  },
  typography: {
    useNextVariants: true
  }
});

export default (Component) => {
  const WithRoot = (props) => {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalCss />
        <Component {...props} />
      </MuiThemeProvider>
    );
  };

  return WithRoot;
};
