import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Page from '../../components/PageWrapper';
import Layout from '../../layouts/Main';
import Login from '../../pages/cms/Login';
import { ROUTES } from './routes';

import WithAuthentication from './WithAuthentication';

const LayoutRoutes = () => (
  <Layout>
    <Switch>
      {ROUTES.map((route, index) => (
        <Route
          key={() => index.toString()}
          path={route.path}
          exact={route.exact}
          render={(props) => <Page {...props} component={route.component} routeName={route.title} />}
        />
      ))}
    </Switch>
  </Layout>
);

export default () => {
  return (
    <Router>
      <Switch>
        {/* NOTE: Should to go first the routes that haven't Layout */}
        <Route exact={true} component={Login} path={'/login'} />
        <WithAuthentication path={'/'} component={LayoutRoutes} />
      </Switch>
    </Router>
  );
};
