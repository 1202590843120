import React, { useContext, useEffect, useState, useMemo }from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core';
import { StoreContext } from '../../../../flux';
import { getNutritionTargets } from '../../../../flux/nutritionTarget/actions';
import Loading from '../../../../components/Loading';

export default (props) => {
    const { state, nutritionTargetDispatch } = useContext(StoreContext);
    const { nutritionTarget } = state;

    useEffect(() => {
        getNutritionTargets(nutritionTargetDispatch);
    }, [!nutritionTargets]);

    const [nutritionTargets, setNutritionTargets] = useState(null);
    useEffect(() => {
        setNutritionTargets(nutritionTarget.list);
    }, [nutritionTarget.list]);

    return useMemo(
        () => (
					<>
						<Loading open={nutritionTarget.loading} />
						<Typography variant={'h4'} component={'h2'}>{'Mi Plan'} </Typography>
						<aside>
							<Card>
								<CardContent>
										{nutritionTargets &&
												nutritionTargets.map((item) => (
												<Link to={{pathname:`/nutritionTarget/${item.id}`, targetInfo: item.id}} key={item.id}>
													<Typography variant={'h6'} component={'h2'}>
														{item.name}
													</Typography>
												</Link>
										))} 
								</CardContent>
								<CardActions>
								<Button size={'small'} variant={'contained'} color={'default'}>
								{'Agregar'}
								</Button>
								</CardActions>
							</Card>
						</aside>
					</>
        )
    )

}