import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2)
  },
  loading: {
    display: 'flex',
    width: '100%',
    height: '100%',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export default ({ open }) => {
  const classes = useStyles();

  return (
    <Modal open={open}>
      <div className={classes.loading}>
        <CircularProgress className={classes.progress} color="secondary" />
      </div>
    </Modal>
  );
};
