import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { TextField, Button, IconButton, InputAdornment, Checkbox, FormControlLabel } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';
import { StoreContext } from '../../../../flux';
import { createIngredient, updateIngredient, getIngredient } from '../../../../flux/ingredients/actions';
import Loading from '../../../../components/Loading';
import useStyles from './style';

const INITIAL_STATE_FORM = {
  name: '',
  locName: { en: '', es: '', pt: '' }
};

export default withRouter((props) => {
  const { history, match } = props;
  const { state, ingredientDispatch } = useContext(StoreContext);
  const { ingredient } = state;
  const {
    params: { id }
  } = match;
  const { loading, success, data } = ingredient;
  const styles = useStyles();

  const [form, setValues] = useState(INITIAL_STATE_FORM);
  useEffect(() => {
    setValues(INITIAL_STATE_FORM);
    setFile(null);
  }, [success]);

  useEffect(() => {
    if (id !== undefined) {
      getIngredient(id, ingredientDispatch);
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      const { name, locName } = data;
      setValues({
        name,
        locName
      });
    }
  }, [data]);

  // const onChangeText = (target) => (event) => {
  //   setValues({ ...form, [target]: event.target.value });
  // };

  const onChangeText = (target, loc) => (event) => {
    if (loc) {
      let data = { [loc]: event.target.value };
      setValues({ ...form, [target]: { en: form[target].en, es: form[target].es, pt: form[target].pt, ...data } });
    } else {
      setValues({ ...form, [target]: event.target.value });
    }
  };

  const onSaveIngredient = async () => {
    console.log('form', form);
    if (id) {
      await updateIngredient({ ...form, file, thumbnail, id }, ingredientDispatch);
    } else {
      await createIngredient({ ...form, file, thumbnail }, ingredientDispatch);
    }
    history.push('/ingredient');
  };

  const [file, setFile] = useState(null);
  const onUploadImage = () => ({ target }) => {
    console.log('onUploadImage', target);
    setFile(target.files[0]);
  };

  const [thumbnail, setThumbnail] = useState(null);
  const onUploadThumbnail = () => ({ target }) => {
    console.log('target.files', target.files);
    setThumbnail(target.files[0]);
  };

  const handleChange = (target) => (event) => {
    console.log('target', target);
    console.log('event', event);
    setValues({ ...form, [target]: !form[target] });
  };

  // = name => event => {
  //   console.log(name)
  //   // setState({ ...state, [name]: event.target.checked });
  // };

  return (
    <div>
      <Loading open={loading} />
      <form className={styles.container} noValidate autoComplete={'off'}>
        <TextField
          label={'Nombre'}
          className={classNames(styles.text__field)}
          value={form.name}
          onChange={onChangeText('name')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
          required
        />

        <TextField
          label={'Nombre Es'}
          className={classNames(styles.text__field)}
          value={form.locName ? form.locName.es : ''}
          onChange={onChangeText('locName', 'es')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
        />

        <TextField
          label={'Nombre En'}
          className={classNames(styles.text__field)}
          value={form.locName ? form.locName.en : ''}
          onChange={onChangeText('locName', 'en')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
        />
        <TextField
          label={'Nombre Pt'}
          className={classNames(styles.text__field)}
          value={form.locName ? form.locName.pt : ''}
          onChange={onChangeText('locName', 'pt')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
        />
        <Divider variant="middle" />

        <div className={styles.upload__container}>
          <input
            accept="image/png"
            className={styles.input}
            id="outlined-button-file"
            type="file"
            onChange={onUploadImage()}
          />
          <label htmlFor="outlined-button-file" className={styles.button__file}>
            <Button variant="text" component="span" size={'large'}>
              <CloudUpload className={styles.icon__upload} />
              {'Subir Imagen'}
            </Button>
          </label>
          <TextField
            label={'Imagen'}
            className={classNames(styles.text__field)}
            value={(file && file.name) || ''}
            variant={'outlined'}
            margin={'dense'}
            fullWidth
            disabled
            component="span"
            required
          />
        </div>

        <div className={styles.upload__container}>
          <input
            accept="image/png"
            className={styles.input}
            id="outlined-button-thumbnail"
            type="file"
            onChange={onUploadThumbnail()}
          />
          <label htmlFor="outlined-button-thumbnail" className={styles.button__file}>
            <Button variant="text" component="span" size={'large'}>
              <CloudUpload className={styles.icon__upload} />
              {'Subir thumbnail'}
            </Button>
          </label>
          <TextField
            label={'Thumbnail'}
            className={classNames(styles.text__field)}
            value={(thumbnail && thumbnail.name) || ''}
            variant={'outlined'}
            margin={'dense'}
            fullWidth
            disabled
            component="span"
            required
          />
        </div>
      </form>

      <Button variant={'contained'} color={'secondary'} onClick={onSaveIngredient} className={styles.btn__save}>
        {'Guardar'}
      </Button>
    </div>
  );
});
