import createReducer from '../createRecucer';
import { HANDLE_ERROR, LOADING, GET_EXERCISES, GET_EXERCISE, CLEAR_EXERCISE } from './types';

export const INITIAL_STATE_EXERCISE = {
  data: null,
  list: null,
  error: null,
  loading: false,
  success: false
};

const handleError = (state, action) => {
  return {
    ...state,
    error: action.payload
  };
};

const setLoading = (state, action) => {
  return {
    ...state,
    loading: action.payload
  };
};

const getExercises = (state, action) => {
  return {
    ...state,
    list: action.payload
  };
};

const getExercise = (state, action) => {
  return {
    ...state,
    data: action.payload
  };
};
const clearExercise = (state, _) => {
  return {
    ...state,
    data: null
  };
};

export default createReducer(INITIAL_STATE_EXERCISE, {
  [LOADING]: setLoading,
  [HANDLE_ERROR]: handleError,
  [GET_EXERCISES]: getExercises,
  [GET_EXERCISE]: getExercise,
  [CLEAR_EXERCISE]: clearExercise
});
