import { makeStyles } from '@material-ui/core';

export default makeStyles({
  header: {
    marginBottom: '1rem'
  },
  content__cards: {
    display: 'grid',
    gridGap: '2rem',
    gridTemplateColumns: 'repeat(6, 1fr)'
  },
  content_card: {
    textOverflow: 'ellipsis',
    // height: '6rem'
  },
  content_actions: {
    justifyContent: 'center'
  },

  card: {
    width: 250
  },
  media: {
    height: 140
  }
});
