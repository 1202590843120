export default {
  level: [{ value: 0, label: 'PRINCIPIANTE' }, { value: 1, label: 'AVANZADO' }, { value: 2, label: 'ATLETA' }],
  gender: [
    {
      value: 0,
      label: 'MUJER'
    },
    {
      value: 1,
      label: 'HOMBRE'
    }
  ],
  somatotipes: [
    {
      value: 0,
      label: 'ECTOMORFO'
    },
    {
      value: 1,
      label: 'MESOMORFO'
    },
    {
      value: 2,
      label: 'ENDOFORMO'
    }
  ],
  objetives: [
    {
      value: 0,
      label: 'PONERTE EN FORMA'
    },
    {
      value: 1,
      label: 'AUMENTO DE MASA'
    },
    {
      value: 2,
      label: 'RTG'
    },
    {
      value: 3,
      label: 'HIPERTROFÍA'
    }
  ]
};
