import React, { forwardRef, Fragment, useContext, useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core';
import useStyles from './style';
import { withRouter } from 'react-router-dom';
import { withTheme } from '@material-ui/styles';
import { StoreContext } from '../../../../flux';
import { getIngredients, deleteIngredient } from '../../../../flux/ingredients/actions';
import Loading from '../../../../components/Loading';

export default withRouter((props) => {
  const { state, ingredientDispatch } = useContext(StoreContext);
  const { ingredient } = state;
  const { history } = props;
  const styles = useStyles();

  useEffect(() => {
    getIngredients(ingredientDispatch);
  }, [!ingredients]);

  const [ingredients, setIngredients] = useState(null);
  useEffect(() => {
    setIngredients(ingredient.list);
  }, [ingredient.list]);



  const onEdit = (id) => {
    history.push(`ingredient/${id}`);
  };

  // const [_delete, setDelete] = useState(false)
  const confirmDelete = (id) => {
    const confirmation = confirm('Realmente desea eliminar el ingrediente');
    if (confirmation) {
      onDelete(id);
    }
  };

  const onDelete = (id) => {
    deleteIngredient(id, ingredientDispatch);
  };



  return useMemo(
    () => (
      <Fragment>
        <Loading open={ingredient.loading} />
        <header className={styles.header}>
          <Button
            variant={'contained'}
            color={'secondary'}
            component={forwardRef((itemProps, ref) => (
              <Link to={'/ingredient'} {...itemProps} innerRef={ref} />
            ))}
          >
            {'Nuevo Ingrediente'}
          </Button>
        </header>
        <section className={styles.content__cards}>
          {ingredients &&
            ingredients.map((item) => (
              <Card className={styles.card} key={item.id}>
                <CardActionArea>
                  <CardMedia
                    className={'styles.media'}
                    component="img"
                    image={item.imageUrl}
                    title={'Contemplative Reptile'}
                  />
                  <CardContent>
                    <Typography variant={'h6'} component={'h2'}>
                      {item.name}
                    </Typography>
                    
                  </CardContent>
                </CardActionArea>
                <Typography variant={'body2'} color={'textSecondary'} component={'p'}>
                      {item.id}
                    </Typography>
                <CardActions>
                  <Button size={'small'} variant={'contained'} color={'default'} onClick={() => onEdit(item.id)}>
                    {'Editar'}
                  </Button>
                  <Button size={'small'} variant={'contained'} color={'default'} onClick={() => confirmDelete(item.id)}>
                    {'Eliminar'}
                  </Button>
                </CardActions>
              </Card>
            ))}
        </section>
      </Fragment>
    ),
    [ingredients]
  );
});
