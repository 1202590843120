import React from 'react';
import { Avatar } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Stars from '@material-ui/icons/Stars';
import PlaceIcon from '@material-ui/icons/Place';
import useStyles from './style';

export default (props) => {
  const { profile, userStats, profilePics } = props;
  const styles = useStyles();
  console.log(props);
  return (
    <div className={styles.container__info_top}>
      <div className={[styles.column, styles.container__info_user].join(' ')}>
        <Avatar src={profilePics&&profilePics.thumb128&&profilePics.thumb128} className={styles.avatar}>
          <AccountCircle fontSize={'large'} />
        </Avatar>
        <span className={styles.full__name}>{`${profile.firstName} ${profile.lastName}`.toUpperCase()}</span>
        <div className={styles.container__location}>
          <PlaceIcon fontSize={'small'} />
          <span>{profile.location}</span>
        </div>
      </div>
      <div className={[styles.column, styles.container__user_stats].join(' ')}>
        <div className={styles.container__user_stats_top}>
          <div className={styles.user__stats}>
            <span className={styles.text__user_stats}>{'Tiempo total de entrenamiento'}</span>
            <span>{userStats.trainedSeconds}</span>
          </div>
          <div className={styles.user__stats}>
            <span className={styles.text__user_stats}>{'Calorías quemadas aproximadas'}</span>
            <span>{userStats.caloriesBurned}</span>
          </div>
        </div>
        <div className={styles.container__user_stats_bottom}>
          <div className={styles.user__stats}>
            <Stars />
            <span className={styles.text__user_stats}>{'Retos'}</span>
            <span>{userStats.challengeDone}</span>
          </div>
          <div className={styles.user__stats}>
            <Stars />
            <span className={styles.text__user_stats}>{'Rutinas'}</span>
            <span>{userStats.workoutsDone}</span>
          </div>
          <div className={styles.user__stats}>
            <Stars />
            <span className={styles.text__user_stats}>{'Recetas'}</span>
            <span>{userStats.recipesDone}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
