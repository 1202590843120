import createReducer from '../createRecucer';
import { SET_TITLE_APPBAR } from './types';

export const INITIAL_STATE_LAYOUT = {
  title: '',
  path_name: ''
};

const setTitleAppBar = (state, action) => {
  return {
    ...state,
    title: action.payload.title,
    path_name: action.payload.path_name
  };
};

export default createReducer(INITIAL_STATE_LAYOUT, {
  [SET_TITLE_APPBAR]: setTitleAppBar
});
