import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { TextField, Button } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';
import { StoreContext } from '../../../../flux';
import { createExercise, updateExercise, getExercise, clearExercise } from '../../../../flux/exercise/actions';
import Loading from '../../../../components/Loading';
import useStyles from './style';

const INITIAL_STATE_FORM = {
  title: '',
  locTitle: { en: '', es: '', pt: '' },
  description: '',
  locDescription: { en: '', es: '', pt: '' },
  errors: '',
  locErrors: { en: '', es: '', pt: '' },
  moves: '',
  locMoves: { en: '', es: '', pt: '' },
  video: null
};

export default withRouter((props) => {
  const { history, match } = props;
  const { state, exerciseDispatch } = useContext(StoreContext);
  const { exercise } = state;
  const {
    params: { id }
  } = match;
  const { loading, success, data } = exercise;
  const styles = useStyles();
  const [form, setValues] = useState(INITIAL_STATE_FORM);
  useEffect(() => {
    setValues(INITIAL_STATE_FORM);
    setFile(null);
  }, [success]);

  useEffect(() => {
    if (id !== undefined) {
      getExercise(id, exerciseDispatch);
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      const { title, locTitle, description, locDescription, errors, locErrors, moves, locMoves } = data;
      setValues({
        title,
        locTitle: locTitle ? locTitle : { en: '', es: title ? title : '', pt: '' },
        description,
        locDescription: locDescription ? locDescription : { en: '', es: description ? description : '', pt: '' },
        errors,
        locErrors: locErrors ? locErrors : { en: '', es: errors ? errors : '', pt: '' },
        moves,
        locMoves: locMoves ? locMoves : { en: '', es: moves ? moves : '', pt: '' }
      });
    }
  }, [data]);

  const onChangeText = (target, loc) => (event) => {
    if (loc) {
      let data = { [loc]: event.target.value };
      setValues({ ...form, [target]: { en: form[target].en, es: form[target].es, pt: form[target].pt, ...data } });
    } else {
      setValues({ ...form, [target]: event.target.value });
    }
  };

  const onSaveExercise = async () => {
    if (id) {
      await updateExercise(
        {
          ...form,
          file,
          title: form.locTitle.es,
          moves: form.locMoves.es,
          errors: form.locErrors.es,
          description: form.locDescription.es,
          thumbnail,
          id,
          idThumbnail: data.thumbnail,
          idVideo: data.video
        },
        exerciseDispatch
      );
    } else {
      await createExercise({ ...form, file, thumbnail }, exerciseDispatch);
    }
    history.push('/exercises');
  };

  const [file, setFile] = useState(null);
  const onUploadVideo = () => ({ target }) => {
    setFile(target.files[0]);
  };

  const [thumbnail, setThumbnail] = useState(null);
  const onUploadThumbnail = () => ({ target }) => {
    setThumbnail(target.files[0]);
  };

  return (
    <div>
      <Loading open={loading} />
      <form className={styles.container} noValidate autoComplete={'off'}>
        {/* <TextField
          label={'Titulo'}
          className={classNames(styles.text__field)}
          value={form.title}
          onChange={onChangeText('title')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
          required
        /> */}

        <TextField
          label={'Titulo Es'}
          className={classNames(styles.text__field)}
          value={form.locTitle ? form.locTitle.es : ''}
          onChange={onChangeText('locTitle', 'es')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
        />

        <TextField
          label={'Titulo En'}
          className={classNames(styles.text__field)}
          value={form.locTitle ? form.locTitle.en : ''}
          onChange={onChangeText('locTitle', 'en')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
        />

        <TextField
          label={'Titulo Pt'}
          className={classNames(styles.text__field)}
          value={form.locTitle ? form.locTitle.pt : ''}
          onChange={onChangeText('locTitle', 'pt')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
        />
        <Divider variant="middle" />
        {/* <TextField
          label={'Descripción'}
          className={classNames(styles.text__field)}
          value={form.description}
          onChange={onChangeText('description')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
          multiline
          rows={4}
        /> */}
        <TextField
          label={'Descripción Es'}
          className={classNames(styles.text__field)}
          value={form.locDescription ? form.locDescription.es : ''}
          onChange={onChangeText('locDescription', 'es')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
          multiline
          rows={4}
        />
        <TextField
          label={'Descripción En'}
          className={classNames(styles.text__field)}
          value={form.locDescription ? form.locDescription.en : ''}
          onChange={onChangeText('locDescription', 'en')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
          multiline
          rows={4}
        />
        <TextField
          label={'Descripción Pt'}
          className={classNames(styles.text__field)}
          value={form.locDescription ? form.locDescription.pt : ''}
          onChange={onChangeText('locDescription', 'pt')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
          multiline
          rows={4}
        />
        <Divider variant="middle" />
        {/* <TextField
          label={'Errores comunes'}
          className={classNames(styles.text__field)}
          value={form.errors}
          onChange={onChangeText('errors')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
          multiline
          rows={4}
        /> */}
        <TextField
          label={'Errores comunes Es'}
          className={classNames(styles.text__field)}
          value={form.locErrors ? form.locErrors.es : ''}
          onChange={onChangeText('locErrors', 'es')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
          multiline
          rows={4}
        />
        <TextField
          label={'Errores comunes En'}
          className={classNames(styles.text__field)}
          value={form.locErrors ? form.locErrors.en : ''}
          onChange={onChangeText('locErrors', 'en')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
          multiline
          rows={4}
        />
        <TextField
          label={'Errores comunes Pt'}
          className={classNames(styles.text__field)}
          value={form.locErrors ? form.locErrors.pt : ''}
          onChange={onChangeText('locErrors', 'pt')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
          multiline
          rows={4}
        />
        <Divider variant="middle" />
        {/* <TextField
          label={'Movimientos'}
          className={classNames(styles.text__field)}
          value={form.moves}
          onChange={onChangeText('moves')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
          multiline
          rows={4}
        /> */}
        <TextField
          label={'Movimientos Es'}
          className={classNames(styles.text__field)}
          value={form.locMoves ? form.locMoves.es : ''}
          onChange={onChangeText('locMoves', 'es')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
          multiline
          rows={4}
        />

        <TextField
          label={'Movimientos En'}
          className={classNames(styles.text__field)}
          value={form.locMoves ? form.locMoves.en : ''}
          onChange={onChangeText('locMoves', 'en')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
          multiline
          rows={4}
        />
        <TextField
          label={'Movimientos Pt'}
          className={classNames(styles.text__field)}
          value={form.locMoves ? form.locMoves.pt : ''}
          onChange={onChangeText('locMoves', 'pt')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
          multiline
          rows={4}
        />
        <Divider variant="middle" />
        <div className={styles.upload__container}>
          <input
            accept="video/m4v"
            className={styles.input}
            id="outlined-button-file"
            type="file"
            onChange={onUploadVideo()}
          />
          <label htmlFor="outlined-button-file" className={styles.button__file}>
            <Button variant="text" component="span" size={'large'}>
              <CloudUpload className={styles.icon__upload} />
              {'Subir video'}
            </Button>
          </label>
          <TextField
            label={'Video'}
            className={classNames(styles.text__field)}
            value={(file && file.name) || ''}
            variant={'outlined'}
            margin={'dense'}
            fullWidth
            disabled
            component="span"
            required
          />
        </div>
        <div className={styles.upload__container}>
          <input
            accept="image/jpg, image/png"
            className={styles.input}
            id="outlined-button-thumbnail"
            type="file"
            onChange={onUploadThumbnail()}
          />
          <label htmlFor="outlined-button-thumbnail" className={styles.button__file}>
            <Button variant="text" component="span" size={'large'}>
              <CloudUpload className={styles.icon__upload} />
              {'Subir thumbnail'}
            </Button>
          </label>
          <TextField
            label={'Thumbnail'}
            className={classNames(styles.text__field)}
            value={(thumbnail && thumbnail.name) || ''}
            variant={'outlined'}
            margin={'dense'}
            fullWidth
            disabled
            component="span"
            required
          />
        </div>
      </form>
      <Button variant={'contained'} color={'secondary'} onClick={onSaveExercise} className={styles.btn__save}>
        {'Guardar'}
      </Button>
    </div>
  );
});
