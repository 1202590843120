import { db, storage } from '../../config/Firebase';
import utilities from '../../util';
import { HANDLE_ERROR, LOADING, GET_EXERCISES, GET_EXERCISE, CLEAR_EXERCISE } from './types';

export const handleError = (error, dispatch) => {
  console.error('error', error);
  return dispatch({ type: HANDLE_ERROR, payload: error });
};
export const setLoading = (loading, dispatch) => {
  return dispatch({ type: LOADING, payload: loading });
};

export const createExercise = async (data, dispatch) => {
  try {
    setLoading(true, dispatch);
    let video, thumbnail;
    if (data.file) {
      video = await uploadVideo(data.file);
    }

    if (data.thumbnail) {
      thumbnail = await uploadThumbnail(data.thumbnail);
    }

    if (data.file && video.error) {
      throw new Error(video.error);
    }
    if (data.thumbnail && thumbnail.error) {
      throw new Error(thumbnail.message);
    }

    delete data.file;
    const exerciseRef = db.collection('trainingExercises');
    await exerciseRef.add({ ...data, ...video, ...thumbnail });
    setLoading(false, dispatch);
  } catch (error) {
    return handleError(error, dispatch);
  }
};

const uploadVideo = async (file, id) => {
  try {
    let videoRef, video, videoUrl;

    if (id) {
      videoRef = await storage.ref(`trainingExercises/${id}`);
      await videoRef.delete();
    }
    videoRef = await storage.ref(`trainingExercises/${utilities.create_UUID()}.m4v`);
    video = await videoRef.put(file);

    videoUrl = await videoRef.getDownloadURL();

    return {
      videoUrl,
      video: video.metadata.name
    };
  } catch (error) {
    return { error: true, message: error.message };
  }
};

const uploadThumbnail = async (file, id) => {
  try {
    let thumbnailRef, thumbnail, thumbnailUrl;
    if (id) {
      thumbnailRef = await storage.ref(`trainingExercisesThumbnails/${id}`);
      await thumbnailRef.delete();
    }
    thumbnailRef = await storage.ref(`trainingExercisesThumbnails/${utilities.create_UUID()}.jpg`);
    thumbnail = await thumbnailRef.put(file);

    thumbnailUrl = await thumbnailRef.getDownloadURL();

    return {
      thumbnailUrl,
      thumbnail: thumbnail.metadata.name
    };
  } catch (error) {
    return { error: true, message: error.message };
  }
};

export const getExercises = async (dispatch) => {
  try {
    setLoading(true, dispatch);
    const exercises = await db.collection('trainingExercises').get();
    const response = await exercises.docs.map((item) => {
      return {
        id: item.id,
        ...item.data()
      };
    });
    setLoading(false, dispatch);
    return dispatch({ type: GET_EXERCISES, payload: response });
  } catch (error) {
    return handleError(error, dispatch);
  }
};

export const getExercise = async (id, dispatch) => {
  try {
    setLoading(true, dispatch);
    const exercise = await db
      .collection('trainingExercises')
      .doc(id)
      .get();
    setLoading(false, dispatch);
    return dispatch({ type: GET_EXERCISE, payload: { id: exercise.id, ...exercise.data() } });
  } catch (error) {
    return handleError(error, dispatch);
  }
};

export const updateExercise = async (data, dispatch) => {
  try {
    setLoading(true, dispatch);
    let video, thumbnail;
    if (data.file) {
      video = await uploadVideo(data.file, data.idVideo);
    }
    if (data.thumbnail) {
      thumbnail = await uploadThumbnail(data.thumbnail, data.idThumbnail);
    }

    if (data.file && video.error) {
      throw new Error(video);
    }
    if (data.thumbnail && thumbnail.error) {
      throw new Error(thumbnail);
    }
    delete data.file;
    delete data.thumbnail;
    delete data.idVideo;
    delete data.idThumbnail;
    const refExercise = await db.collection('trainingExercises').doc(data.id);
    delete data.id;
    let media = Object.assign({}, { ...video, ...thumbnail });

    await refExercise.set(
      {
        ...data,
        ...media
      },
      { merge: true }
    );
    setLoading(false, dispatch);
    dispatch({ type: CLEAR_EXERCISE });
  } catch (error) {
    return handleError(error, dispatch);
  }
};

export const deleteExercise = async (id, dispatch) => {
  try {
    setLoading(true, dispatch);
    await db
      .collection('trainingExercises')
      .doc(id)
      .delete();
    getExercises(dispatch);
  } catch (error) {
    return handleError(error, dispatch);
  }
};

export const clearExercise = async (dispatch) => {
  dispatch({ type: CLEAR_EXERCISE });
};
