import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import RouterApp from './config/RouterCms';
import withRoot from './WithRoot';
import Store from './flux';

const App = () => {
  return (
    <Store>
      <CssBaseline />
      <RouterApp />
    </Store>
  );
};

export default withRoot(App);
