import React, { Fragment, useContext, useEffect, useState, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core';
import { sizing } from '@material-ui/system';
import { StoreContext } from '../../../../flux';
import { getExercises, clearExercise, deleteExercise } from '../../../../flux/exercise/actions';
import Loading from '../../../../components/Loading';
import useStyles from './style';
import copy from 'copy-to-clipboard';

export default withRouter((props) => {
  const { state, exerciseDispatch } = useContext(StoreContext);
  const { exercise } = state;
  const { history } = props;
  const styles = useStyles();

  useEffect(() => {
    getExercises(exerciseDispatch);
  }, [!exercises]);

  const [exercises, setExercises] = useState(null);
  useEffect(() => {
    setExercises(exercise.list);
  }, [exercise.list]);

  const onCreate = () => {
    clearExercise(exerciseDispatch);
    history.push('exercise');
  };

  const onEdit = (id) => {
    history.push(`exercise/${id}`);
  };

  // const [_delete, setDelete] = useState(false)
  const confirmDelete = (id) => {
    const confirmation = confirm('Realmente desea eliminar el ejercicio');
    if (confirmation) {
      onDelete(id);
    }
  };
  const copyId = (text) => {
    copy(text);
  };

  const onDelete = (id) => {
    deleteExercise(id, exerciseDispatch);
  };

  return useMemo(
    () => (
      <Fragment>
        <Loading open={exercise.loading} />
        <header className={styles.header}>
          <Button variant={'contained'} color={'secondary'} onClick={onCreate}>
            {'Nuevo ejercicio'}
          </Button>
        </header>
        <section className={styles.content__cards}>
          {exercises &&
            exercises.map((item) => (
              <Card width={300} height={300} className={styles.card} key={item.id}>
                <CardActionArea>
                  {item.thumbnailUrl && (
                    <CardMedia
                      className={'styles.media'}
                      component="img"
                      image={item.thumbnailUrl}
                      title={'Contemplative Reptile'}
                    />
                  )}
                  <CardContent className={styles.content_card}>
                    <Typography variant={'subtitle2'} component={'h6'}>
                      {item.locTitle ? item.locTitle.es : item.title}
                    </Typography>
                    {item.locTitle && item.locTitle.en && (
                      <Typography variant={'subtitle2'} component={'h6'}>
                        {item.locTitle.en}
                      </Typography>
                    )}
                    {item.locTitle && item.locTitle.pt && (
                      <Typography variant={'subtitle2'} component={'h6'}>
                        {item.locTitle.pt}
                      </Typography>
                    )}

                    {item.locDescription ? (
                      <>
                        <Typography variant={'body2'} color={'textSecondary'} component={'p'}>
                          {item.locDescription.es}
                        </Typography>
                        <Typography variant={'body2'} color={'textSecondary'} component={'p'}>
                          {item.locDescription.en}
                        </Typography>
                        <Typography variant={'body2'} color={'textSecondary'} component={'p'}>
                          {item.locDescription.pt}
                        </Typography>
                      </>
                    ) : (
                      <Typography variant={'body2'} color={'textSecondary'} component={'p'}>
                        {item.description}
                      </Typography>
                    )}
                    <Typography variant={'inherit'} color={'textSecondary'} component={'p'}>
                      id: {item.id}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions className={styles.content_actions}>
                  <Button size={'small'} variant={'contained'} color={'default'} onClick={() => onEdit(item.id)}>
                    {'Editar'}
                  </Button>
                  <Button size={'small'} variant={'contained'} color={'default'} onClick={() => confirmDelete(item.id)}>
                    {'Eliminar'}
                  </Button>
                  <Button size={'small'} variant={'contained'} color={'default'} onClick={() => copyId(item.id)}>
                    {'ID'}
                  </Button>
                </CardActions>
              </Card>
            ))}
        </section>
      </Fragment>
    ),
    [exercises, exercise.loading]
  );
});
