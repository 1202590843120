import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  Typography,
  Chip,
  Grid
} from '@material-ui/core';
import _ from 'lodash';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { Home as HomeIcon, DeleteIcon, FormatAlignCenterIcon } from '@material-ui/icons';
import MaterialTable from 'material-table';

import { CloudUpload } from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';
import { StoreContext } from '../../../../flux';
import { createRecipe, updateRecipe, getRecipe } from '../../../../flux/recipes/actions';
import Loading from '../../../../components/Loading';
import useStyles from './style';
import { async } from 'q';

const INITIAL_STATE_FORM = {
  name: '',
  foodType: 'breakfast',
  locName: { en: '', es: '', pt: '' },
  preparation: [],
  targets: [],
  locPreparation: [],
  nutritionalTable: { ectomorfo: [], endomorfo: [], mesomorfo: [] },
  endomorfo: [],
  ectomorfo: [],
  mesomorfo: []
};

const options = {
  filtering: false,
  pageSize: 10
};

export default withRouter((props) => {
  const { history, match } = props;
  const { state, recipeDispatch } = useContext(StoreContext);
  const { recipe } = state;
  const {
    params: { id }
  } = match;
  const { loading, success, data } = recipe;
  const styles = useStyles();

  const [form, setValues] = useState(INITIAL_STATE_FORM);

  const [foodType, setFoodType] = React.useState('breakfast');
  const [targetGender, setTargetGender] = React.useState('2');
  // const [toggleType, settoggleType] = useState('breakfast');

  useEffect(() => {
    setValues(INITIAL_STATE_FORM);
    setFile(null);
  }, [success]);

  useEffect(() => {
    if (id !== undefined) {
      getRecipe(id, recipeDispatch);
    }
  }, [id]);

  const [preparation, setPreparation] = useState([]);

  const [targets, setTargets] = useState([]);

  const [nutritionalEndo, setNutritionalEndo] = useState([]);
  const [nutritionalEcto, setNutritionalEcto] = useState([]);
  const [nutritionalMeso, setNutritionalMeso] = useState([]);

  const [ectomorfoIngredients, setEctomorfoIngredients] = useState([]);

  const [endomorfoIngredients, setEndomorfoIngredients] = useState([]);

  const [mesomorfoIngredients, setMesomorfoIngredients] = useState([]);

  useEffect(() => {
    if (data) {
      const {
        name,
        locName,
        preparation,
        targets,
        locPreparation,
        foodType,
        targetGender,
        nutritionalTable,
        endomorfo,
        ectomorfo,
        mesomorfo
      } = data;
      let steps = [];

      let ectoIngre = [];
      let endoIngre = [];
      let mesoIngre = [];
      //   nutritionalTable: { ectomorfo: [], endomorfo: [], mesomorfo: [] }

      if (endomorfo) {
        endoIngre = [];
        console.log('endomorfo', endomorfo);

        for (let index = 0; index < endomorfo.length; index++) {
          console.log('ecto:options', endomorfo[index]);

          for (let odex = 0; odex < endomorfo[index].options.length; odex++) {
            console.log('ecto:item', {
              ...endomorfo[index].options[odex],
              pos: parseInt(odex),
              group: parseInt(index)
            });

            endoIngre = [
              ...endoIngre,
              { ...endomorfo[index].options[odex], pos: parseInt(odex), group: parseInt(index) }
            ];
          }

          if (index == endomorfo.length - 1) {
            console.log('endoIngre', endoIngre);
            setEndomorfoIngredients(endoIngre);
            console.log('endomorfoIngredients', endomorfoIngredients);
          }
        }
      }

      if (ectomorfo) {
        ectoIngre = [];
        console.log('ectomorfo', ectomorfo);

        for (let index = 0; index < ectomorfo.length; index++) {
          console.log('ecto:options', ectomorfo[index]);

          for (let odex = 0; odex < ectomorfo[index].options.length; odex++) {
            console.log('ecto:item', {
              ...ectomorfo[index].options[odex],
              pos: parseInt(odex),
              group: parseInt(index)
            });

            ectoIngre = [
              ...ectoIngre,
              { ...ectomorfo[index].options[odex], pos: parseInt(odex), group: parseInt(index) }
            ];
          }

          if (index == ectomorfo.length - 1) {
            console.log('ectoIngre', ectoIngre);
            setEctomorfoIngredients(ectoIngre);
            console.log('ectomorfoIngredients', ectomorfoIngredients);
          }
        }
      }

      if (mesomorfo) {
        mesoIngre = [];
        console.log('mesomorfo', mesomorfo);

        for (let index = 0; index < mesomorfo.length; index++) {
          console.log('ecto:options', mesomorfo[index]);

          for (let odex = 0; odex < mesomorfo[index].options.length; odex++) {
            console.log('ecto:item', {
              ...mesomorfo[index].options[odex],
              pos: parseInt(odex),
              group: parseInt(index)
            });

            mesoIngre = [
              ...mesoIngre,
              { ...mesomorfo[index].options[odex], pos: parseInt(odex), group: parseInt(index) }
            ];
          }

          if (index == mesomorfo.length - 1) {
            console.log('mesoIngre', mesoIngre);
            setMesomorfoIngredients(mesoIngre);
            console.log('mesomorfoIngredients', mesomorfoIngredients);
          }
        }
      }

      console.log('nutritionalTable', nutritionalTable);
      if (nutritionalTable.endomorfo) {
        setNutritionalEndo(nutritionalTable.endomorfo);
      }

      if (nutritionalTable.ectomorfo) {
        setNutritionalEcto(nutritionalTable.ectomorfo);
      }

      if (nutritionalTable.mesomorfo) {
        setNutritionalMeso(nutritionalTable.mesomorfo);
      }

      if (locPreparation) {
        for (let index = 0; index < locPreparation.length; index++) {
          steps = [...steps, { en: locPreparation[index].en, es: locPreparation[index].es, pt: locPreparation[index].pt }];
        }
      } else {
        for (let index = 0; index < preparation.length; index++) {
          steps = [...steps, { en: '', es: preparation[index] }];
        }
      }
      console.log('steps', steps);
      setPreparation(steps);
      setTargets(targets);
      setFoodType(foodType);

      setTargetGender(targetGender.toString());

      console.log('targets', targets);
      setValues({
        name,
        locName,
        preparation,
        locPreparation
      });
    }
  }, [data]);

  const [stateTable, setStateTable] = React.useState({
    columns: [{ title: 'Ingles', field: 'en' }, { title: 'Español', field: 'es' }, { title: 'Portugués', field: 'pt' }],
    data: []
  });

  useEffect(() => {
    if (preparation) {
      new Promise((resolve) => {
        setTimeout(() => {
          resolve();
          setStateTable((prevState) => {
            const data = preparation;
            // data.push(...preparation);
            return { ...prevState, data };
          });
        }, 600);
      });
    }
  }, [preparation]);

  // start endo
  const [stateTableEndo, setStateTableEndo] = React.useState({
    columns: [
      {
        title: 'Nombre',
        field: 'name',
        lookup: {
          sodium: 'sodium',
          protein: 'protein',
          fat: 'fat',
          carbohydrates: 'carbohydrates',
          sugar: 'sugar',
          calories: 'calories'
        }
      },
      { title: 'Cantidad', field: 'quantity', type: 'numeric' }
    ],
    data: []
  });

  useEffect(() => {
    if (nutritionalEndo) {
      new Promise((resolve) => {
        setTimeout(() => {
          resolve();
          setStateTableEndo((prevState) => {
            const data = nutritionalEndo; //[...prevState.data];
            // data.push(...nutritionalEndo);
            return { ...prevState, data };
          });
        }, 600);
      });
    }
  }, [nutritionalEndo]);
  // end endo

  // start ecto
  const [stateTableEcto, setStateTableEcto] = React.useState({
    columns: [
      {
        title: 'Nombre',
        field: 'name',
        lookup: {
          sodium: 'sodium',
          protein: 'protein',
          fat: 'fat',
          carbohydrates: 'carbohydrates',
          sugar: 'sugar',
          calories: 'calories'
        }
      },
      { title: 'Cantidad', field: 'quantity', type: 'numeric' }
    ],
    data: []
  });

  useEffect(() => {
    if (nutritionalEcto) {
      new Promise((resolve) => {
        setTimeout(() => {
          resolve();
          setStateTableEcto((prevState) => {
            const data = nutritionalEcto; //[...prevState.data];
            // data.push(...nutritionalEcto);
            return { ...prevState, data };
          });
        }, 600);
      });
    }
  }, [nutritionalEcto]);
  // end endo

  // start meso
  const [stateTableMeso, setStateTableMeso] = React.useState({
    columns: [
      {
        title: 'Nombre',
        field: 'name',
        lookup: {
          sodium: 'sodium',
          protein: 'protein',
          fat: 'fat',
          carbohydrates: 'carbohydrates',
          sugar: 'sugar',
          calories: 'calories'
        }
      },
      { title: 'Cantidad', field: 'quantity', type: 'numeric' }
    ],
    data: []
  });

  useEffect(() => {
    if (nutritionalMeso) {
      new Promise((resolve) => {
        setTimeout(() => {
          resolve();
          setStateTableMeso((prevState) => {
            const data = nutritionalMeso; //[...prevState.data];
            // data.push(...nutritionalMeso);
            return { ...prevState, data };
          });
        }, 600);
      });
    }
  }, [nutritionalMeso]);
  // end meso

  // start ecto ingredients
  const [stateTableEctoIngredients, setStateTableEctoIngredients] = React.useState({
    columns: [
      {
        title: 'Nombre',
        field: 'name'
      },
      { title: 'Cantidad', field: 'quantity' },
      { title: 'pos', field: 'pos', type: 'numeric' },
      { title: 'group', field: 'group', type: 'numeric' },
      { title: 'id ingrediente', field: 'ingredientId' }
    ],
    data: []
  });

  useEffect(() => {
    if (ectomorfoIngredients.length > 0) {
      console.log('ectomorfoIngredients:useEffect', ectomorfoIngredients);
      new Promise((resolve) => {
        setTimeout(() => {
          resolve();
          setStateTableEctoIngredients((prevState) => {
            const data = ectomorfoIngredients; // [...prevState.data];
            // data.push(...ectomorfoIngredients);
            return { ...prevState, data };
          });
        }, 600);
      });
    }
  }, [ectomorfoIngredients.length > 0]);
  // end ecto

  // start meso ingredients
  const [stateTableMesoIngredients, setStateTableMesoIngredients] = React.useState({
    columns: [
      {
        title: 'Nombre',
        field: 'name'
      },
      { title: 'Cantidad', field: 'quantity' },
      { title: 'pos', field: 'pos', type: 'numeric' },
      { title: 'group', field: 'group', type: 'numeric' },
      { title: 'id ingrediente', field: 'ingredientId' }
    ],
    data: []
  });

  useEffect(() => {
    if (mesomorfoIngredients.length > 0) {
      console.log('mesomorfoIngredients:useEffect', mesomorfoIngredients);
      new Promise((resolve) => {
        setTimeout(() => {
          resolve();
          setStateTableMesoIngredients((prevState) => {
            const data = mesomorfoIngredients; //[...prevState.data];
            // data.push(...mesomorfoIngredients);
            return { ...prevState, data };
          });
        }, 600);
      });
    }
  }, [mesomorfoIngredients.length > 0]);
  // end ecto

  // start endo ingredients
  const [stateTableEndoIngredients, setStateTableEndoIngredients] = React.useState({
    columns: [
      {
        title: 'Nombre',
        field: 'name'
      },
      { title: 'Cantidad', field: 'quantity' },
      { title: 'pos', field: 'pos', type: 'numeric' },
      { title: 'group', field: 'group', type: 'numeric' },
      { title: 'id ingrediente', field: 'ingredientId' }
    ],
    data: []
  });

  useEffect(() => {
    if (endomorfoIngredients.length > 0) {
      console.log('endomorfoIngredients:useEffect', endomorfoIngredients);
      new Promise((resolve) => {
        setTimeout(() => {
          resolve();
          setStateTableEndoIngredients((prevState) => {
            const data = endomorfoIngredients; // [...prevState.data];
            // data.push(...endomorfoIngredients);
            return { ...prevState, data };
          });
        }, 600);
      });
    }
  }, [endomorfoIngredients.length > 0]);
  // end endo

  const onChangeText = (target, loc) => (event) => {
    if (loc) {
      let data = { [loc]: event.target.value };
      setValues({
        ...form,
        [target]: {
          en: form[target] && form[target].en ? form[target].en : '',
          es: form[target] && form[target].es ? form[target].es : '',
          pt: form[target] && form[target].pt ? form[target].pt : '',
          ...data
        }
      });
    } else {
      setValues({ ...form, [target]: event.target.value });
    }
  };

  const onSaveRecipe = async () => {
    let steps = [];
    let prepare = [];
    let nutritionalTable = {};

    console.log('stateTableEndo', stateTableEndo.data);
    console.log('stateTableEcto', stateTableEcto.data);
    console.log('stateTableMeso', stateTableMeso.data);

    if (stateTableEndo.data.length > 0) {
      nutritionalTable = { ...nutritionalTable, endomorfo: stateTableEndo.data };
    }

    if (stateTableEcto.data.length > 0) {
      nutritionalTable = { ...nutritionalTable, ectomorfo: stateTableEcto.data };
    }

    if (stateTableMeso.data.length > 0) {
      nutritionalTable = { ...nutritionalTable, mesomorfo: stateTableMeso.data };
    }

    for (let index = 0; index < stateTable.data.length; index++) {
      steps = [...steps, stateTable.data[index].es];
    }

    for (let o = 0; o < stateTable.data.length; o++) {
      prepare = [...prepare, { en: stateTable.data[o].en, es: stateTable.data[o].es, pt: stateTable.data[o].pt }];
    }

    let endoData = [];
    let mesoData = [];
    let ectoData = [];

    console.log('stateTableEctoIngredients', stateTableEctoIngredients.data);

    if (stateTableEctoIngredients.data.length > 0) {
      console.log('ectomorfoIngredients', stateTableEctoIngredients.data.length);
      const arrEcto = stateTableEctoIngredients.data;
      for (let index = 0; index < arrEcto.length; index++) {
        let list = _.filter(arrEcto, (item) => parseInt(item.group) === index);

        if (list.length > 0) {
          ectoData = [...ectoData, { options: list }];
          console.log('list', index, list);
          console.log('ectoData', ectoData);
        }
      }
    }

    if (stateTableEndoIngredients.data.length > 0) {
      console.log('endomorfoIngredients', stateTableEndoIngredients.data.length);
      const arrEndo = stateTableEndoIngredients.data;
      for (let index = 0; index < arrEndo.length; index++) {
        let list = _.filter(arrEndo, (item) => parseInt(item.group) === index);
        if (list.length > 0) {
          endoData = [...endoData, { options: list }];
          console.log('list', index, list);
          console.log('endoData', endoData);
        }
      }
    }

    if (stateTableMesoIngredients.data.length > 0) {
      console.log('mesomorfoIngredients', stateTableMesoIngredients.data.length);
      const arrMeso = stateTableMesoIngredients.data;
      for (let index = 0; index < arrMeso.length; index++) {
        let list = _.filter(arrMeso, (item) => parseInt(item.group) === index);
        if (list.length > 0) {
          mesoData = [...mesoData, { options: list }];
          console.log('list', index, list);
          console.log('mesoData', mesoData);
        }
      }
    }

    let data = {
      name: form.name,
      locName: { en: form.locName.en, es: form.locName.es, pt: form.locName.pt },
      preparation: steps,
      locPreparation: prepare,
      foodType,
      targetGender: parseInt(targetGender),
      nutritionalTable,
      targets,
      ectomorfo: ectoData,
      endomorfo: endoData,
      mesomorfo: mesoData
    };

    console.log('data:onSave', data);
    console.log('form', form);
    if (id) {
      await updateRecipe({ ...form, ...data, file, thumbnail, id }, recipeDispatch);
    } else {
      await createRecipe({ ...form, ...data, file, thumbnail }, recipeDispatch);
    }
    history.push('/recipes');
  };

  const [file, setFile] = useState(null);
  const onUploadImage = () => ({ target }) => {
    console.log('onUploadImage', target);
    setFile(target.files[0]);
  };

  const [thumbnail, setThumbnail] = useState(null);
  const onUploadThumbnail = () => ({ target }) => {
    console.log('target.files', target.files);
    setThumbnail(target.files[0]);
  };

  const handleChange = (target) => (event) => {
    console.log('target', target);
    console.log('event', event);
    setValues({ ...form, [target]: !form[target] });
  };

  const handleFoodType = (event, newFoodType) => {
    setFoodType(newFoodType);
    console.log(newFoodType);
  };

  const handleTargetGender = (event, newTargetGender) => {
    console.log('newTargetGender', newTargetGender);
    setTargetGender(newTargetGender);
    console.log(newTargetGender);
  };

  const handleTargets = (event, newTarget) => {
    setTargets(newTarget);
    console.log(newTarget);
  };
  return (
    <div>
      <Loading open={loading} />
      <form className={styles.container} noValidate autoComplete={'off'}>
        <TextField
          label={'Nombre'}
          className={classNames(styles.text__field)}
          value={form.name}
          onChange={onChangeText('name')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
          required
        />

        <TextField
          label={'Nombre Es'}
          className={classNames(styles.text__field)}
          value={form.locName ? form.locName.es : ''}
          onChange={onChangeText('locName', 'es')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
        />

        <TextField
          label={'Nombre En'}
          className={classNames(styles.text__field)}
          value={form.locName ? form.locName.en : ''}
          onChange={onChangeText('locName', 'en')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
        />
        <TextField
          label={'Nombre Pt'}
          className={classNames(styles.text__field)}
          value={form.locName ? form.locName.pt : ''}
          onChange={onChangeText('locName', 'pt')}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
        />
        <Divider variant="middle" />

        {/* <section className={styles.content__cards}>
          <section className={styles.content__cards}>
            <Typography variant={'h6'} component={'h2'}>
              Preparacion
            </Typography>
          </section>
          {preparation &&
            preparation.map((item, index) => (
              <section>
                <Chip label={`${index + 1} - ${item}`} onDelete={handleDelete} />

              </section>
            ))}
        </section> */}

        {/* <TextField
          label={`Agregar paso`}
          className={classNames(styles.text__field)}
          value={step}
          onChange={() => onAChangeStep()}
          variant={'outlined'}
          margin={'dense'}
          fullWidth
          multiline
          rows={4}
        />
        <Button variant={'contained'} color={'secondary'} onClick={() => onAddStep(step)} className={styles.btn__save}>
          {'Agregar'}
        </Button>
        <Divider variant="middle" /> */}

        <MaterialTable
          options={options}
          title="Preparación"
          columns={stateTable.columns}
          data={stateTable.data}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  setStateTable((prevState) => {
                    const data = [...prevState.data];
                    data.push(newData);
                    return { ...prevState, data };
                  });
                }, 600);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  if (oldData) {
                    setStateTable((prevState) => {
                      const data = [...prevState.data];
                      data[data.indexOf(oldData)] = newData;
                      return { ...prevState, data };
                    });
                  }
                }, 600);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  setStateTable((prevState) => {
                    const data = [...prevState.data];
                    data.splice(data.indexOf(oldData), 1);
                    return { ...prevState, data };
                  });
                }, 600);
              })
          }}
        />

        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <Typography variant={'subtitle1'} component={'h2'}>
              Target
            </Typography>
            <div className={styles.toggleContainer}>
              <ToggleButtonGroup value={targets} onChange={handleTargets}>
                <ToggleButton value="FGboTzHkcnhflf5jl0m2">Abdomen</ToggleButton>
                <ToggleButton value="GkAlZkClp5CIOi851GIG">Masa muscular</ToggleButton>
                <ToggleButton value="O8Fdd3x1dNjNrSiN4prI">Mantenerse</ToggleButton>
                <ToggleButton value="hB6SnK5jAPezEOtelidn">Reducción</ToggleButton>
                <ToggleButton value="mgGrP3Wep2zWRy6y3sdF">Semana</ToggleButton>
              </ToggleButtonGroup>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <Typography variant={'subtitle1'} component={'h2'}>
              Tipo de comida
            </Typography>
            <div className={styles.toggleContainer}>
              <ToggleButtonGroup value={foodType} exclusive onChange={handleFoodType}>
                <ToggleButton value="breakfast">Desayuno</ToggleButton>
                <ToggleButton value="Snak">Snak</ToggleButton>
                <ToggleButton value="lunch">Almuerzo</ToggleButton>
                <ToggleButton value="dinner">Cena</ToggleButton>
              </ToggleButtonGroup>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <Typography variant={'subtitle1'} component={'h2'}>
              Genero
            </Typography>

            <div className={styles.toggleContainer}>
              <ToggleButtonGroup value={targetGender} exclusive onChange={handleTargetGender}>
                <ToggleButton value={'0'}>Mujer</ToggleButton>
                <ToggleButton value={'1'}>Hombre</ToggleButton>
                <ToggleButton value={'2'}>Ambos</ToggleButton>
              </ToggleButtonGroup>
            </div>
          </Grid>
        </Grid>

        <Typography variant={'subtitle1'} component={'h2'}>
          Taba nutricional
        </Typography>

        <div className={styles.nutritional__container}>
          <MaterialTable
            options={options}
            title="Endomorfo Tabla Nutricional"
            columns={stateTableEndo.columns}
            data={stateTableEndo.data}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    setStateTableEndo((prevState) => {
                      const data = [...prevState.data];
                      data.push(newData);
                      return { ...prevState, data };
                    });
                  }, 600);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    if (oldData) {
                      setStateTableEndo((prevState) => {
                        const data = [...prevState.data];
                        data[data.indexOf(oldData)] = newData;
                        return { ...prevState, data };
                      });
                    }
                  }, 600);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    setStateTableEndo((prevState) => {
                      const data = [...prevState.data];
                      data.splice(data.indexOf(oldData), 1);
                      return { ...prevState, data };
                    });
                  }, 600);
                })
            }}
          />

          <MaterialTable
            options={options}
            title="Ectomorfo Tabla Nutricional"
            columns={stateTableEcto.columns}
            data={stateTableEcto.data}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    setStateTableEcto((prevState) => {
                      const data = [...prevState.data];
                      data.push(newData);
                      return { ...prevState, data };
                    });
                  }, 600);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    if (oldData) {
                      setStateTableEcto((prevState) => {
                        const data = [...prevState.data];
                        data[data.indexOf(oldData)] = newData;
                        return { ...prevState, data };
                      });
                    }
                  }, 600);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    setStateTableEcto((prevState) => {
                      const data = [...prevState.data];
                      data.splice(data.indexOf(oldData), 1);
                      return { ...prevState, data };
                    });
                  }, 600);
                })
            }}
          />

          <MaterialTable
            options={options}
            title="Mesomorfo Tabla Nutricional"
            columns={stateTableMeso.columns}
            data={stateTableMeso.data}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    setStateTableMeso((prevState) => {
                      const data = [...prevState.data];
                      data.push(newData);
                      return { ...prevState, data };
                    });
                  }, 600);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    if (oldData) {
                      setStateTableMeso((prevState) => {
                        const data = [...prevState.data];
                        data[data.indexOf(oldData)] = newData;
                        return { ...prevState, data };
                      });
                    }
                  }, 600);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    setStateTableMeso((prevState) => {
                      const data = [...prevState.data];
                      data.splice(data.indexOf(oldData), 1);
                      return { ...prevState, data };
                    });
                  }, 600);
                })
            }}
          />
        </div>

        <Typography variant={'subtitle1'} component={'h2'}>
          Ingredientes
        </Typography>
        <div className={styles.nutritional__container}>
          <MaterialTable
            options={options}
            title="Endomorfo Tabla Ingredients"
            columns={stateTableEndoIngredients.columns}
            data={stateTableEndoIngredients.data}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    setStateTableEndoIngredients((prevState) => {
                      const data = [...prevState.data];
                      data.push(newData);
                      return { ...prevState, data };
                    });
                  }, 600);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    if (oldData) {
                      setStateTableEndoIngredients((prevState) => {
                        const data = [...prevState.data];
                        data[data.indexOf(oldData)] = newData;
                        return { ...prevState, data };
                      });
                    }
                  }, 600);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    setStateTableEndoIngredients((prevState) => {
                      const data = [...prevState.data];
                      data.splice(data.indexOf(oldData), 1);
                      return { ...prevState, data };
                    });
                  }, 600);
                })
            }}
          />
          <MaterialTable
            options={options}
            title="Ectomorfo Tabla Ingredients"
            columns={stateTableEctoIngredients.columns}
            data={stateTableEctoIngredients.data}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    setStateTableEctoIngredients((prevState) => {
                      const data = [...prevState.data];
                      data.push(newData);
                      return { ...prevState, data };
                    });
                  }, 600);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    if (oldData) {
                      setStateTableEctoIngredients((prevState) => {
                        const data = [...prevState.data];
                        data[data.indexOf(oldData)] = newData;
                        return { ...prevState, data };
                      });
                    }
                  }, 600);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    setStateTableEctoIngredients((prevState) => {
                      const data = [...prevState.data];
                      data.splice(data.indexOf(oldData), 1);
                      return { ...prevState, data };
                    });
                  }, 600);
                })
            }}
          />

          <MaterialTable
            options={options}
            title="Mesomorfo Tabla Ingredients"
            columns={stateTableMesoIngredients.columns}
            data={stateTableMesoIngredients.data}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    setStateTableMesoIngredients((prevState) => {
                      const data = [...prevState.data];
                      data.push(newData);
                      return { ...prevState, data };
                    });
                  }, 600);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    if (oldData) {
                      setStateTableMesoIngredients((prevState) => {
                        const data = [...prevState.data];
                        data[data.indexOf(oldData)] = newData;
                        return { ...prevState, data };
                      });
                    }
                  }, 600);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    setStateTableMesoIngredients((prevState) => {
                      const data = [...prevState.data];
                      data.splice(data.indexOf(oldData), 1);
                      return { ...prevState, data };
                    });
                  }, 600);
                })
            }}
          />
        </div>

        <div className={styles.upload__container}>
          <input
            accept="image/png"
            className={styles.input}
            id="outlined-button-file"
            type="file"
            onChange={onUploadImage()}
          />
          <label htmlFor="outlined-button-file" className={styles.button__file}>
            <Button variant="text" component="span" size={'large'}>
              <CloudUpload className={styles.icon__upload} />
              {'Subir Imagen'}
            </Button>
          </label>
          <TextField
            label={'Imagen'}
            className={classNames(styles.text__field)}
            value={(file && file.name) || ''}
            variant={'outlined'}
            margin={'dense'}
            fullWidth
            disabled
            component="span"
            required
          />
        </div>

        <div className={styles.upload__container}>
          <input
            accept="image/png"
            className={styles.input}
            id="outlined-button-thumbnail"
            type="file"
            onChange={onUploadThumbnail()}
          />
          <label htmlFor="outlined-button-thumbnail" className={styles.button__file}>
            <Button variant="text" component="span" size={'large'}>
              <CloudUpload className={styles.icon__upload} />
              {'Subir thumbnail'}
            </Button>
          </label>
          <TextField
            label={'Thumbnail'}
            className={classNames(styles.text__field)}
            value={(thumbnail && thumbnail.name) || ''}
            variant={'outlined'}
            margin={'dense'}
            fullWidth
            disabled
            component="span"
            required
          />
        </div>
      </form>

      <Button variant={'contained'} color={'secondary'} onClick={onSaveRecipe} className={styles.btn__save}>
        {'Guardar'}
      </Button>
    </div>
  );
});
