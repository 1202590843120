import createReducer from '../createRecucer';
import { LOADING, HANDLE_ERROR, AUTHENTICATED, SEARCH_RESULT } from './types';

export const INITIAL_STATE_USER = {
  data: null,
  token: sessionStorage.getItem('token'),
  uid: sessionStorage.getItem('uid'),
  search_result: { profile: null },
  loading: false
};

const handleError = (state, action) => {
  return {
    ...state,
    error: action.payload
  };
};

const setLoading = (state, action) => {
  return {
    ...state,
    loading: action.payload
  };
};

const loginUser = (state, action) => {
  return {
    ...state,
    token: action.token,
    uid: action.uid
  };
};

const searchUser = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    search_result: payload ? payload : { not_found: 'No se encontró ningún usuario.' }
  };
};

export default createReducer(INITIAL_STATE_USER, {
  [LOADING]: setLoading,
  [HANDLE_ERROR]: handleError,
  [AUTHENTICATED]: loginUser,
  [SEARCH_RESULT]: searchUser
});
