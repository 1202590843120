import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  container: {
    padding: '1rem',
    minHeight: '80vh'
  },
  row: {
    display: 'flex',
    flexDirection: 'column'
  },
  column: {
    display: 'grid'
  },
  column2: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr'
  },
  input: {
    width: '100%'
  },
  form: {
    width: '50%'
  },
  container_level: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: '0.5rem',
    marginBottom: '1rem'
  },
  card: {
    backgroundColor: theme.palette.primary.grayLight
  },
  title_level: {
    fontSize: '1rem',
    fontWeight: 'bold'
  },
  input_level: {
    backgroundColor: 'white'
  },
  button_exercise: {
    marginRight: theme.spacing(1)
  },
  add_exercise: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'space-between',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '0.5rem'
  },
  input_exercise: {
    marginTop: '5px'
  },
  button_save: {
    marginTop: '1rem'
  }
}));
