import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  container: {
    marginTop: '1rem'
  },
  row: {
    display: 'flex',
    flexDirection: 'column'
  },
  column: {
    display: 'flex'
  },
  avatar: {
    margin: 10,
    width: 100,
    height: 100
  },
  container__location: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1rem'
  },
  container__info: {
    width: '100%'
  },
  container__profile: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '0.5rem',
    marginTop: '1rem'
  },
  input: {
    marginRight: theme.spacing(1),
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(2)
  },
  panel__header: {
    backgroundColor: theme.palette.primary.grayLight
  },
  container__notes: {
    padding: '1rem'
  },
  text_note: {
    marginBottom: '0.5rem'
  },
  date__note: {
    fontSize: '0.7rem'
  },
  container__premium: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '0.5rem',
    marginTop: '1rem'
  },
  summary__subscription: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    gridGap: '0.5rem',
    justifyContent: 'flex-start'
  },
  container__add_days: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    gridGap: '0.5rem'
  },
  button_add_days: {
    marginTop: theme.spacing(1)
  }
}));
