import React, { createContext, useReducer } from 'react';
import layoutReducer, { INITIAL_STATE_LAYOUT } from './layout/reducer';
import userReducer, { INITIAL_STATE_USER } from './user/reducer';
import exerciseReducer, { INITIAL_STATE_EXERCISE } from './exercise/reducer';
import articleReducer, { INITIAL_STATE_ARTICLE } from './article/reducer';
import ingredientReducer, { INITIAL_STATE_INGREDIENT } from './ingredients/reducer';
import recipeReducer, { INITIAL_STATE_RECIPE } from './recipes/reducer';
import nutritionTargetReducer, { INITIAL_STATE_TARGET } from './nutritionTarget/reducer';

export const StoreContext = createContext({});

export default (props) => {
  const [layout, layoutDispatch] = useReducer(layoutReducer, INITIAL_STATE_LAYOUT);
  const [user, userDispatch] = useReducer(userReducer, INITIAL_STATE_USER);
  const [exercise, exerciseDispatch] = useReducer(exerciseReducer, INITIAL_STATE_EXERCISE);
  const [article, articleDispatch] = useReducer(articleReducer, INITIAL_STATE_ARTICLE);
  const [ingredient, ingredientDispatch] = useReducer(ingredientReducer, INITIAL_STATE_INGREDIENT);
  const [recipe, recipeDispatch] = useReducer(recipeReducer, INITIAL_STATE_RECIPE);
  const [nutritionTarget, nutritionTargetDispatch] = useReducer(nutritionTargetReducer, INITIAL_STATE_TARGET);

  return (
    <StoreContext.Provider
      value={{
        state: { layout, user, exercise, article, ingredient, recipe, nutritionTarget },
        layoutDispatch,
        userDispatch,
        exerciseDispatch,
        articleDispatch,
        ingredientDispatch,
        recipeDispatch,
        nutritionTargetDispatch
      }}
    >
      {props.children}
    </StoreContext.Provider>
  );
};
